import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-benefit-plan',
  templateUrl: './benefit-plan.component.html',
  styleUrls: ['./benefit-plan.component.scss']
})
export class BenefitPlanComponent implements OnInit {

  // Almacenamos el plan abierto, child_age_parent : false
  plan = {id: -1, name: "", benefits: []};
  isLoading = true;

  constructor(private route: ActivatedRoute,
    private planService : PlanService,
    private router: Router
  ) {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idPlan = params['id'];
      if(idPlan != undefined){
        // Buscar plan en el server
        this.planService.fetchById(idPlan, data => {
          this.isLoading = false;
          this.plan = data;
        });
      }
    });
   }

  ngOnInit() {
  }

  onClickSave(){
    this.planService.editBenefits(this.plan.id, this.plan.benefits, data => {
      this.router.navigate(['/plans']); 
    });
  }

  onClickBack(){
    this.router.navigate(['/plans']); 
  }
}
