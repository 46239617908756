import { Injectable } from '@angular/core';
import { ApiResponse } from '@mobileia/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@mobileia/authentication';
import { environment } from '../../environments/environment';
import { MIAFilter } from '@mobileia/layout-elite';

@Injectable({
  providedIn: 'root'
})
export class SanatoriumService {

  private sanatoriums = [];

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  public create(sanatorium: any, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      sanatorium.access_token = token;
      this.requestCreate(sanatorium, callback);
    });
  }

  private requestCreate(params: any,  callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/sanatorium/create', params).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
  });
  }

  /**
     * Devuelve el listado de resultdo
     * @param callback
     */
    public fetchAll(page: number, where: string, search: string, filter: MIAFilter, limit: number, callback: (data: any) => void) {
      this.authService.getAccessToken().subscribe(token => {
          this.requestFetchAll(token, page, where, search, filter, limit, callback);
      });
    }

    /*public addNew(name: string, website: string, callback: (data: any) => void){
      this.authService.getAccessToken().subscribe(token => {
        this.requestAdd(token, name, website, callback);
    });
    }

    public edit(id: number, name: string, website: string, callback: (data: any) => void){
      this.authService.getAccessToken().subscribe(token => {
        this.requestEdit(token, id, name, website, callback);
    });
    }*/

    public deleteSanatorium(id: number, callback: (data: any) => void){
      this.authService.getAccessToken().subscribe(token => {
        this.requestDelete(token, id, callback);
    });
    }

  private requestFetchAll(access_token: string, page: number, where: string,
    search: string, filter: MIAFilter, limit: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<any>>(environment.baseUrl + 'admin/sanatorium/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc,
      page: page, where: where, search: search, limit: limit}).subscribe(data => {
        if (data.success) {
          // Guardamos eventos cargados en el servicio
            this.sanatoriums = data.response.data;
            // enviamos datos
            callback(data.response);
        }
    });
  }

  /*private requestAdd(access_token: string, name: string, website: string, callback: (data: any) => void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/add-company', {access_token : access_token, name: name, website: website}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
    });
  }

  private requestEdit(access_token: string, id: number, name: string, website: string, callback: (data: any) => void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/edit-company', {access_token : access_token, id: id, name: name, website: website}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
    });
  }*/

  private requestDelete(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/sanatorium/delete', {
      access_token : access_token, id: id}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
    });
  }

  public getSanatoriumById(id: number): any {
    console.log(id);
    console.log(this.sanatoriums);
    for (let i = 0; i < this.sanatoriums.length; i++) {
      const company = this.sanatoriums[i];
      if (company.id == id) {
        return company;
      }
    }
    return null;
  }
}
