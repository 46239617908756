import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users = [];

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.loadUsers();
  }

  removeItem(item){
    this.userService.delete(item.id).subscribe(data => {});
    this.users.splice(this.users.indexOf(item), 1);
  }

  loadUsers() {
    this.userService.fetchAll().subscribe(data => {
      if (data.success) {
        this.users = data.response;
      }
    });
  }
}
