import { Component, Inject, OnInit } from '@angular/core';
import { CommentsService } from 'src/app/services/comments.service';
import { DOCUMENT } from '@angular/common'; 

@Component({
  selector: 'app-blog-comments',
  templateUrl: './blog-comments.component.html',
  styleUrls: ['./blog-comments.component.scss']
})
export class BlogCommentsComponent implements OnInit {

  companies = [];
  filterCompany = -1;
  comments = [];
  isLoading = true;
  all_selected_values: number[] = [];

  columnsTable = [
    {title: 'Sel'},
    {title: 'Post'},
    {title: 'Nombre'},
    {title: 'Email'},
    {title: 'Opinión'},
    {title: 'Creado'},
    {title: 'Acción'},
  ];
  

  constructor(private commentsService: CommentsService, 
              @Inject(DOCUMENT) document: Document) { }

  
  ngOnInit() {

    this.loadComments();
  }
  //Función para leer opinión sobre el blog
  loadComments()
  {
    this.commentsService.fetchAllBlog(data =>{
      this.comments = data;
      //console.log(this.comments);
      this.isLoading = false;
    })
  }
  removeRow(id){
    document.getElementById(id).remove();
  }
  
  //Función para aceptar opinión sobre el blog
  aceptar(itemId)
  {
    if(confirm('¿Desea Aceptar la opinión?')){
      this.isLoading = true;
      this.commentsService.aceptBlogComment(itemId, data => {
        //this.removeRow(itemId);
        this.loadComments();
      });
    }else{
      return false;
    }
  }
  //Función para eliminar opinión sobre el blog
  removeItem(itemId)
  {
      //console.log(itemId);
      this.isLoading = true;
      this.commentsService.deleteBlogComment(itemId, data => {
        this.loadComments();
      });     
  }

  eliminarSeleccion()
  {
    if(this.all_selected_values.length > 0)
    {
      if(confirm('¿Está seguro de continuar con la eliminación, no se podrá revertir?'))
      {
        this.isLoading = true;
        this.all_selected_values.forEach(element => {
          this.commentsService.deleteBlogComment(element, data => {
          });     

        });
        this.all_selected_values = [];
        this.loadComments();
      }
      return false;
    }

    alert('No hay objetos seleccionados.');

  }

  //Función para guardar los check seleccionados
  onChange(value: number): void {
    if (this.all_selected_values.includes(value)) {
      this.all_selected_values = this.all_selected_values.filter((item) => item !== value);
    } else {
      this.all_selected_values.push(value);
    }
    //console.log(this.all_selected_values);
  }

}
