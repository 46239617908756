import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MiaDynamicFormService } from '@mobileia/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResidencyService } from 'src/app/services/residency.service';

@Component({
  selector: 'app-residency-add',
  templateUrl: './residency-add.component.html',
  styleUrls: ['./residency-add.component.scss']
})
export class ResidencyAddComponent implements OnInit {

  form: FormGroup;
  item = null;
  
  public fields: any[] = [
    {
      type: 'text',
      name: 'name',
      label: 'Nombre',
      value: '',
      required: false,
    },
    {
      type: 'select',
      name: 'status',
      label: 'Estado',
      value: 0,
      options: [
        { key: 0, label: 'Desactivada' },
        { key: 1, label: 'Activada' }
      ],
      required: false,
    },
    {
      type: 'button',
      name: 'save',
      label: 'Guardar'
    }
  ];

  constructor(private dynamicFormService : MiaDynamicFormService, 
    private router: Router,
    private residencyService : ResidencyService,
    private route: ActivatedRoute) {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idCompany = params['id'];
      if(idCompany != undefined){
        this.loadItem(idCompany);
      } else {
        // Preparamos formulario
      this.form = dynamicFormService.generateFormGroup(this.fields);
      }
    });
  }

  ngOnInit() {
  }

  loadItem(itemId: number) {
    this.residencyService.fetchById(itemId).toPromise().then(data => {
      if (!data.success) {
        this.router.navigate(['/residency']); 
        return;
      }

      this.item = data.response;
      this.fields[0].value = this.item.name;
      this.fields[1].value = parseInt(this.item.status);

      // Preparamos formulario
      this.form = this.dynamicFormService.generateFormGroup(this.fields);
    });
  }

  onSend(values){
    if(this.item && this.item.id){
      values.id = this.item.id;
    }
    this.residencyService.save(values).toPromise().then(data => {
      this.router.navigate(['/residency']); 
    });

  }

}
