import {Component, OnInit} from '@angular/core';
import {SellerService} from "../../../services/services/java/seller.service";
import {MiaDynamicFormService} from "@mobileia/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Seller} from "../../../entities/models/sellers/seller";
import {ResidencyJavaService} from "../../../services/services/java/residency-java.service";
import {SellerCompanyService} from "../../../services/services/java/seller-company.service";
import {ToastrService} from "ngx-toastr";
import {SellerCompanyFormModel} from "../../../entities/models/sellers/seller-company-form.model";

@Component({
  selector: 'app-seller-company-form',
  templateUrl: './seller-company-form.component.html',
  styleUrls: ['./seller-company-form.component.scss']
})
export class SellerCompanyFormComponent implements OnInit {
  formGroup: FormGroup = this.formBuilder.group({
    formType: [FormType.NORMAL, [Validators.required]],
    sellerId: ['', [Validators.required]],
  });

  formTypeOptions: FormType[] = [FormType.NORMAL, FormType.PREPAID, FormType.BOTH];
  allSellers: Seller[] = [];
  allResidencies: ResidenceCheckbox[] = [];

  companyName: string;
  private companyId: number;

  constructor(
    private sellerService: SellerService,
    private sellerCompanyService: SellerCompanyService,
    private residencyService: ResidencyJavaService,
    private formBuilder: FormBuilder,
    private dynamicFormService: MiaDynamicFormService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.route.queryParams
      .subscribe(queryParams => {
        this.companyId = queryParams.companyId;
        this.companyName = queryParams.companyName;
        if (this.companyId === undefined) this.goBack();
      })
  }

  ngOnInit() {
    Promise
      .all([this.sellerService.getAll(), this.residencyService.getAll()])
      .then((response) => {
        this.allSellers = response[0];
        this.allResidencies = response[1].map(r => ({
          residenceId: r.id,
          residenceName: r.name,
          active: false
        })).sort((r1, r2) => r1.residenceName > r2.residenceName ? 1 : -1)
      });
  }

  submit(): void {
    if (this.formGroup.invalid) return;

    const residenciesId: ResidenceCheckbox[] = this.allResidencies.filter(r => r.active);
    if (residenciesId.length == 0) {
      this.toastrService.error("¡Selecione alguna Residencia!", "Error")
      return;
    }
    const formType: FormType = this.formGroup.controls['formType'].value;
    const isNormalType: boolean = formType === FormType.NORMAL || formType === FormType.BOTH;
    const isPrepaidType: boolean = formType === FormType.PREPAID || formType === FormType.BOTH;

    const sellerForm: SellerCompanyFormModel = new SellerCompanyFormModel(
      this.companyId,
      this.formGroup.controls['sellerId'].value,
      isNormalType,
      isPrepaidType,
      undefined,
      residenciesId.map(r => r.residenceId),
    );

    this.sellerCompanyService
      .postSellerCompany(sellerForm)
      .then((messages: string[]) => {
        if (messages.length > 0) {
          messages.forEach((message: string) => {
            const words = message.split(' - ');
            const form_type = words[0].match(/[a-zA-Z]+/g)[0];
            this.toastrService.error(words[1], `Error - Formulario ${form_type}`);
          });
        }
        this.goBack();
      })
      .catch(() => this.toastrService.error("¡Error al asignar al vendedor!", "Error"));
  }

  goBack(): void {
    this.router.navigate(["/company/sellers"], {
      queryParams: {
        companyId: this.companyId,
        companyName: this.companyName
      }
    })
  }

  parseTypeOptions(type: FormType): string {
    switch (type) {
      case FormType.NORMAL:
        return "Formulario de Prepaga";
      case FormType.PREPAID:
        return "Pedidos páginas de resultados";
      case FormType.BOTH:
        return "Ambas";
    }
  }
}

interface ResidenceCheckbox {
  residenceId: number,
  residenceName: string,
  active: boolean
}

enum FormType {
  NORMAL,
  PREPAID,
  BOTH
}
