import { Phone } from "./phone";
import { Speciality } from "./speciality";

export class Address {
    id: number;
    address: string;
    country: string;
    state: string;
    county: string;
    city: string;
    sublocality: string;
    neighborhood: string;
    street: string;
    housenumber: string;
    postal: string;
    lat: number;
    lng: number;
    place: string;
    address_complement: string;
    scrapped_address: string;
    phones: Phone[] = [new Phone()];
}
