import {Injectable} from '@angular/core';
import {ApiResponse} from '@mobileia/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService, MiaAuthHttpService} from '@mobileia/authentication';
import {environment} from '../../environments/environment';
import { CompanyComments } from '../entities/company-comments';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends MiaAuthHttpService {

  private companies = [];
  private comment = [];

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public applyPercent(companyId: number, type: string, percent: string, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestApplyPercent(token, companyId, type, percent, callback);
    });
  }

  private requestApplyPercent(accessToken: string, companyId: number, type: string, percent: string, callback: (data: boolean) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/apply-percent',
      {access_token: accessToken, company_id: companyId, type: type, percent: percent}).subscribe(data => {
      if (data.success) {
        callback(true);
      }
    });
  }

  /**
   * Devuelve el listado de resultdo
   * @param callback
   */
  public fetchAll(callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAll(token, callback);
    });
  }

  public addNew(name: string, website: string, url_video_institucional: string, whatsapp_number: string, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestAdd(token, name, website, url_video_institucional, whatsapp_number, callback);
    });
  }

  public edit(id: number, name: string, website: string, url_video_institucional: string, whatsapp_number: string, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestEdit(token, id, name, website, url_video_institucional, whatsapp_number, callback);
    });
  }

  public deleteCompany(id: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestDelete(token, id, callback);
    });
  }

  private requestFetchAll(access_token: string, callback: (data: [any]) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/companies', {access_token: access_token}).subscribe(data => {
      if (data.success) {
        // Guardamos eventos cargados en el servicio
        this.companies = data.response;
        //console.log(this.companies);
        // enviamos datos
        callback(data.response);
      }
    });
  }

  private requestAdd(access_token: string, name: string, website: string, url_video_institucional: string, whatsapp_number: string, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/add-company', {
      access_token: access_token,
      name: name,
      website: website,
      url_video_institucional: url_video_institucional,
      whatsapp_number: whatsapp_number
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  private requestEdit(access_token: string, id: number, name: string, website: string, url_video_institucional: string, whatsapp_number: string, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/edit-company', {
      access_token: access_token,
      id: id,
      name: name,
      website: website,
      url_video_institucional: url_video_institucional,
      whatsapp_number: whatsapp_number
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  private requestDelete(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/delete-company', {
      access_token: access_token,
      id: id
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public getCompanyById(id: number): any {
    for (let i = 0; i < this.companies.length; i++) {
      const company = this.companies[i];
      if (company.id == id) {
        return company;
      }
    }
    return null;
  }

  /*
  ** FUNCION PARA OBTENER OPINION POR ID 
  */
   public getCommentById(id:number, callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestCommentById(token, id, callback);
    });
  }

  //funciones request para obtener una opinión del blog por id

  private requestCommentById(access_token: string, id:number, callback: (data: [any]) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + '/company-comments/get-comment', {access_token: access_token, id: id}).subscribe(data => {
      if (data.success) {
        // Guardamos eventos cargados en el servicio
        this.comment = data.response;
        //console.log(this.comments);
        // enviamos datos
        callback(data.response);
      }
    });
  }

  /*
  ** FUNCION PARA GRABAR LA EDICIÓN DE UNA OPINIÓN 
  */
  public saveComment(commentID: number, comment: string, response: string, acept: boolean): Observable<ApiResponse<CompanyComments>> {
    return this.postAuthObject(environment.baseUrl + '/company-comments/edit-comment', { id: commentID, comment: comment, response: response, acept: acept});
  }

}
