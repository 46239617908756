import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthenticationModule } from '@mobileia/authentication'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutEliteModule } from '@mobileia/layout-elite';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { AddCompanyComponent } from './pages/add-company/add-company.component';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@mobileia/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { PlansComponent } from './plans/plans.component';
import { FormsModule } from '../../node_modules/@angular/forms';
import { EditPlanComponent } from './pages/edit-plan/edit-plan.component';
import { PricePlanComponent } from './pages/price-plan/price-plan.component';
import { AffiliateTypePipe } from './filters/affiliate-type.pipe';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SanatoriumPlanComponent } from './pages/sanatorium-plan/sanatorium-plan.component';
import { CreatePlanComponent } from './pages/create-plan/create-plan.component';
import { SanatoriumsComponent } from './pages/sanatoriums/sanatoriums.component';
import { CreateSanatoriumComponent } from './create-sanatorium/create-sanatorium.component';
import { BenefitPlanComponent } from './pages/benefit-plan/benefit-plan.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { PopupComponent } from './pages/popup/popup.component';
import { UsersComponent } from './pages/users/users.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { DoctorsComponent } from './pages/doctors/doctors.component';
import { AddDoctorComponent } from './pages/add-doctor/add-doctor.component';
import { PageTagsComponent } from './pages/page-tags/page-tags.component';
import { PageTagsAddComponent } from './pages/page-tags-add/page-tags-add.component';
import { ResidencyComponent } from './pages/residency/residency.component';
import { ResidencyAddComponent } from './pages/residency-add/residency-add.component';
import { FormsComponent } from './pages/forms/forms.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogRelationComponent } from './pages/blog-relation/blog-relation.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SellersComponent } from './pages/companies/sellers/sellers.component';
import { SellerFormComponent } from './pages/companies/seller-form/seller-form.component';
import { SellersCompanyComponent } from './pages/companies/sellers-company/sellers-company.component';
import { SellerCompanyFormComponent } from './pages/companies/seller-company-form/seller-company-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MailingComponent } from './pages/mailing/mailing.component';
import {DatePipe} from "./pipes/date.pipe";
import {MailStatusPipe} from "./pipes/mail-status.pipe";
import { CompanyCommentsComponent } from './pages/company-comments/company-comments.component';
import { BlogCommentsComponent } from './pages/blog-comments/blog-comments.component';
import { EditBlogCommentComponent } from './pages/edit-blog-comment/edit-blog-comment.component';
import { EditCompanyCommentComponent } from './pages/edit-company-comment/edit-company-comment.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CompaniesComponent,
    AddCompanyComponent,
    PlansComponent,
    EditPlanComponent,
    PricePlanComponent,
    AffiliateTypePipe,
    DatePipe,
    MailStatusPipe,
    NumberOnlyDirective,
    SanatoriumPlanComponent,
    CreatePlanComponent,
    SanatoriumsComponent,
    CreateSanatoriumComponent,
    BenefitPlanComponent,
    ContactsComponent,
    PopupComponent,
    UsersComponent,
    AddUserComponent,
    DoctorsComponent,
    AddDoctorComponent,
    PageTagsComponent,
    PageTagsAddComponent,
    ResidencyComponent,
    ResidencyAddComponent,
    FormsComponent,
    BlogComponent,
    BlogRelationComponent,
    SellersComponent,
    SellerFormComponent,
    SellersCompanyComponent,
    SellerCompanyFormComponent,
    MailingComponent,
    CompanyCommentsComponent,
    BlogCommentsComponent,
    EditBlogCommentComponent,
    EditCompanyCommentComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AuthenticationModule.forRoot({apiKey: "20"}),
    NgbModule.forRoot(),
    LayoutEliteModule,
    RouterModule,
    AppRoutingModule,
    CoreModule,
    SweetAlert2Module.forRoot(),
    GooglePlaceModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
