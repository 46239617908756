import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService, MIAFilter } from '@mobileia/layout-elite';
import { SanatoriumService } from '../../services/sanatorium.service';

@Component({
  selector: 'app-sanatoriums',
  templateUrl: './sanatoriums.component.html',
  styleUrls: ['./sanatoriums.component.scss']
})
export class SanatoriumsComponent implements OnInit {

  sanatoriums : any;
  filter = new MIAFilter();
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = "";
  search = "";

  constructor(private menuService : LayoutMenuService,
    private tableService : LayoutTableService,
  private sanatoriumService : SanatoriumService) {

    this.menuService.activatedSidebar(4);
  }

  ngOnInit() {
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadSanatoriums();
  }

  loadSanatoriums() {
    this.isLoading = true;
    this.sanatoriumService.fetchAll(this.pageCurrent, this.where, this.search, this.filter, 50, response => {
      var pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i + 1));
      }
      this.pages = pages;
      this.sanatoriums = response;
      this.isLoading = false;
    });
  }

  onClickPage(page: number) {
    this.pageCurrent = page;
    this.loadSanatoriums();
  }

  onSendSearch() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    this.loadSanatoriums();
  }

  onChangeWhere() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Iniciar busqueda
    this.where = '';
    this.loadSanatoriums();
  }

  onChangeFilter(newFilter) {
    // Verificar si se seleccinoo un filtro correcto
    if (newFilter.title == '' || newFilter.title == undefined) {
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadSanatoriums();
  }

  removeItem(item) {
    this.sanatoriumService.deleteSanatorium(item.id, data => {});
    this.sanatoriums.data.splice(this.sanatoriums.data.indexOf(item), 1);
  }
}
