export class BlogComments {
    id: number;
    blog_id: number;
    name: string;
    email: string;
    comment: string;
    response: string;
    status: number;
    created_at: string;
    updated_at: string;
    deleted: number;
}