import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'datePipe',
  pure: false
})
export class DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    return new Date(value).toLocaleDateString("es-AR", options);
  }
}
