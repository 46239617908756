export class SellerCompanyFormModel {
  constructor(
    public readonly companyId: number,
    public readonly sellerId: number,
    public readonly form: boolean,
    public readonly contact: boolean,
    public readonly residenceId?: number,
    public readonly residenciesId?: number[],
    public readonly probability?: number,
  ) {
  }
}

export interface ResidenceSellers {
  residenceId: number,
  residenceName: string,
  sellers: SimpleSellerResponse[],
  sellersForm: SimpleSellerResponse[],
}

export interface SimpleSellerResponse {
  id: number,
  name: string,
  mail: string,
  optionalMails: string[],
  probability: number
}
