import { Component, OnInit } from '@angular/core';
import { LayoutMenuService, LayoutTableService, MIAFilter } from '@mobileia/layout-elite';
import { PlanService } from '../services/plan.service';
import { CompanyService } from '../services/company.service';
import {NgbModal, ModalDismissReasons, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  plans: any;
  companies = [];
  filter = new MIAFilter();
  filterCompany = -1;
  filterProvider = -1;
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = '';
  search = '';

  selectedPlan = { id: 0, name: '' };
  selectedSignal = '0';
  selectedAmount = null;

  selectedCompany = -1;
  selectedName = '';

  constructor(private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private companyService: CompanyService,
  private planService: PlanService,
  private modalService: NgbModal) {

    this.menuService.activatedSidebar(3);
  }

  ngOnInit() {
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    // Configurar si existe un filtro preseleccionado
    this.filterCompany = this.planService.companyFilterSaved;
    this.onChangeWhere();
    // Cargar información
    this.loadCompanies();
    this.loadPlans();
  }

  loadCompanies() {
    this.companyService.fetchAll(data => {
      this.companies = data;
    });
  }

  loadPlans() {
    this.isLoading = true;
    this.planService.fetchAll(this.pageCurrent, this.where, this.search, this.filter, response => {
      var pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i + 1));
      }
      this.pages = pages;
      this.plans = response;
      this.isLoading = false;
    });
  }

  onClickPage(page: number) {
    this.pageCurrent = page;
    this.loadPlans();
  }

  onSendSearch() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    this.loadPlans();
  }

  onChangeWhere() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Iniciar busqueda
    this.where = "";
    if(this.filterCompany > 0){
      // Guardamos filtro
      this.planService.companyFilterSaved = this.filterCompany;
      this.where = "plans.company_id:" + this.filterCompany;
    } else {
      // Guardamos filtro
      this.planService.companyFilterSaved = this.filterCompany;
    }
    if(this.filterProvider != -1){
      if(this.where == ''){
        this.where = "sales.provider_id:" + this.filterProvider;
      }else{
        this.where += ";sales.provider_id:" + this.filterProvider;
      }
    }
    this.loadPlans();
  }

  onChangeFilter(newFilter) {
    // Verificar si se seleccinoo un filtro correcto
    if(newFilter.title == '' || newFilter.title == undefined){
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadPlans();
  }

  removeItem(item) {
    this.planService.deletePlan(item.id, data => {});
    this.plans.data.splice(this.plans.data.indexOf(item), 1);
  }

  onEnterForm(event) {
    if (event.keyCode === 13) {
      this.loadPlans();
    }
  }

  applyPercent() {
    this.planService.applyPercent(this.selectedPlan.id, this.selectedSignal, this.selectedAmount, data => {

    });
  }

  copyPlan() {
    this.planService.copyAll(this.selectedPlan.id, this.selectedCompany, this.selectedName, data => {
      this.loadPlans();
    });
  }

  open(content, item) {
    this.selectedPlan = item;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result == '100') {
        this.applyPercent();
      }else if (result == '108') {
        this.copyPlan();
      }
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return false;
  }
}
