import { Address } from "./address";
import { Prepaga } from "./prepaga";
import { Speciality } from "./speciality";
import { Plan } from "./plan";

export class Doctor {
    id = 0;
    name: string;
    addresses: Address[] = [new Address()];
    prepagas: Prepaga[] = [];
    specialities: Speciality[] = [];
    plans: Plan[] = [];

    remove_addresses: Address[] = [new Address()];
}
