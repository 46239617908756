import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '@mobileia/core';
import { Pagetag } from '../entities/pagetag';

@Injectable({
  providedIn: 'root'
})
export class PagetagsService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public cleanCacheBlog(): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'blog/clear-cache', {});
  }

  public save(page: Pagetag): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/pagetags/save', page);
  }

  public fetchById(pageId: number): Observable<ApiResponse<Pagetag>> {
    return this.postAuthObject(environment.baseUrl + 'admin/pagetags/fetch', { id: pageId });
  }

  public fetchAll(): Observable<ApiResponse<Array<any>>> {
    return this.postAuthObject(environment.baseUrl + 'admin/pagetags', {});
  }
}
