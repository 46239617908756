import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Mail} from "../../../entities/models/mail";
import {EndpointsUrl} from "./endpointsUrl";

@Injectable({
  providedIn: 'root'
})
export class MailingService extends EndpointsUrl {
  constructor(private httpClient: HttpClient) {
    super();
    this.endpoint = "mail";
  }

  public getAll(): Promise<Mail[]> {
    return this.httpClient
      .get<Mail[]>(this.getAllUrl())
      .toPromise()
      .then(ms => ms.map(m => ({
        id: m.id,
        toMails: m.toMails,
        ccMails: m.ccMails,
        from: m.from,
        subject: m.subject,
        status: m.status,
        created_at: new Date(m.created_at)
      })));
  }
}
