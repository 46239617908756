import { Component, OnInit } from '@angular/core';
import { MIAFilter, LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { BlogService } from 'src/app/services/blog.service';
import { MIATableModel, ApiPagination } from '@mobileia/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  posts : Array<any>;
  params = new MIATableModel();
  filter = new MIAFilter();
  pageCurrent = 1;
  pages = [];
  isLoading = true;
  where = "";
  search = "";

  constructor(private menuService : LayoutMenuService,
    private tableService : LayoutTableService,
  private blogService : BlogService) {

    this.menuService.activatedSidebar(4);
  }

  ngOnInit() {
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadSanatoriums();
  }

  loadSanatoriums() {
    this.isLoading = true;
    this.blogService.fetchLast(this.params).toPromise().then(data => {
      this.isLoading = false;
      if(!data.success){
        return;
      }
      this.posts = data.response;
    });
  }

  onClickPage(page: number) {
    this.pageCurrent = page;
    this.loadSanatoriums();
  }

  onSendSearch() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    this.loadSanatoriums();
  }

  onChangeWhere() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Iniciar busqueda
    this.where = '';
    this.loadSanatoriums();
  }

  onChangeFilter(newFilter) {
    // Verificar si se seleccinoo un filtro correcto
    if (newFilter.title == '' || newFilter.title == undefined) {
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadSanatoriums();
  }
}
