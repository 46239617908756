import { Injectable } from '@angular/core';
import { MiaAuthHttpService, AuthenticationService, MIAUser } from '@mobileia/authentication';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@mobileia/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public create(data: any): Observable<ApiResponse<MIAUser>> {
    return this.postAuthObject(environment.baseUrl + 'admin/user/create', data);
  }

  public delete(userId: number): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/user/delete', { id: userId });
  }

  public fetchAll(): Observable<ApiResponse<[any]>> {
    console.log('admin users');
    return this.postAuthArray(environment.baseUrl + 'admin/users', {});
  }
}
