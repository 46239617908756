import { Component, OnInit } from '@angular/core';
import { PagetagsService } from 'src/app/services/pagetags.service';

@Component({
  selector: 'app-page-tags',
  templateUrl: './page-tags.component.html',
  styleUrls: ['./page-tags.component.scss']
})
export class PageTagsComponent implements OnInit {

  pages = new Array<any>();

  constructor(
    private pageService: PagetagsService,
  ) { }

  ngOnInit() {
    this.loadPages();
  }

  loadPages() {
    this.pageService.fetchAll().subscribe(data => {
      if (data.success) {
        this.pages = data.response;
      }
    });
  }
}
