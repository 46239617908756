import {Pipe, PipeTransform} from "@angular/core";
import {MailStatus} from "../entities/models/mail-status.e";

@Pipe({
  name: 'mailStatusPipe',
  pure: false
})
export class MailStatusPipe implements PipeTransform {
  transform(value: MailStatus, ...args: any[]): string {
    switch (value) {
      case MailStatus.ERRORS: return "ERROR";
      case MailStatus.SENDED: return "ENVIADO";
      case MailStatus.INITIALIZED: return "INICIALIZADO";
    }
  }
}
