import {Component, OnInit} from '@angular/core';
import {Mail} from "../../entities/models/mail";
import {MailingService} from "../../services/services/java/mailing.service";
import {ToastrService} from "ngx-toastr";
import {MailStatus} from "../../entities/models/mail-status.e";

@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss']
})
export class MailingComponent implements OnInit {
  mails: Mail[] = [{
    id: 1,
    from: "l@l",
    ccMails: ["cc@cc", "c2@c2"],
    toMails: ["to@to", "to2@to2"],
    status: MailStatus.SENDED,
    subject: "Asuntazo",
    created_at: new Date(2021, 7, 14)
  },
  {
    id: 2,
    from: "l@l",
    ccMails: ["ccjj@ccjj", "cj2@cj2"],
    toMails: ["toj@toj", "toj2@toj2"],
    status: MailStatus.SENDED,
    subject: "Asuntazos",
    created_at: new Date(2021, 7, 14)
  }];

  constructor(
    private mailingService: MailingService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.requestLastMails()
  }

  requestLastMails(): void {
    this.mailingService.getAll()
      .then(mails => this.mails = mails)
      .catch(() => this.toastrService.error('Error', '¡Error al traer sus mails!'));
  }
}
