import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '@mobileia/core';
import { Affiliate } from '../entities/affiliate';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public fetchById(itemId: number): Observable<ApiResponse<Affiliate>> {
    return this.postAuthObject(environment.baseUrl + 'admin/affiliate/fetch', { id: itemId });
  }

  public fetchAll(): Observable<ApiResponse<Array<any>>> {
    return this.postAuthObject(environment.baseUrl + 'admin/affiliate/list', {});
  }
}
