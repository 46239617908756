import { Component, OnInit } from '@angular/core';
import { MiaDynamicFormService } from '@mobileia/core';
import { CompanyService } from 'src/app/services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyComments } from 'src/app/entities/company-comments';

@Component({
  selector: 'app-edit-company-comment',
  templateUrl: './edit-company-comment.component.html',
  styleUrls: ['./edit-company-comment.component.scss']
})
export class EditCompanyCommentComponent implements OnInit {

  newComment = new CompanyComments();
  blog = null;
  
  constructor(private dynamicFormService : MiaDynamicFormService, 
    private router: Router,
    private companyService : CompanyService,
    private route: ActivatedRoute) {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idComment :number = params['id'];
      if(idComment != undefined){
        this.blog = this.companyService.getCommentById(idComment, data =>{            
          this.newComment = data[0];
          //console.log(this.blog[0]);
        });
      }
      // Preparamos formulario
      //this.form = dynamicFormService.generateFormGroup(this.fields);
    });
  }

  ngOnInit() {
  }

  onClickSave(acept){
    //console.log(this.newComment);
    if(this.newComment.response == null){ this.newComment.response = ''; }
    this.companyService.saveComment(this.newComment.id, this.newComment.comment, this.newComment.response, acept).subscribe(data => {
        if (data.success) {  
          this.router.navigate(['/company-comments']);
        }
      }); 
  }
  onClickBack() {
    this.router.navigate(['/company-comments']);
  }
}
