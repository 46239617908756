import { Injectable } from '@angular/core';
import { MiaAuthHttpService, AuthenticationService, MIAUser } from '@mobileia/authentication';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '@mobileia/core';
import { Status } from '../entities/status';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { 
    super(http, authService);
  }

  public fetchById(itemId: number): Observable<ApiResponse<Status>> {
    return this.postAuthObject(environment.baseUrl + 'admin/status/fetch', { id: itemId });
  }

  public fetchAll(): Observable<ApiResponse<Array<any>>> {
    console.log('admin status mio');
    return this.postAuthObject(environment.baseUrl + 'admin/status/list', {});
  }
}
