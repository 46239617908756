import { Injectable } from '@angular/core';
import { ApiResponse } from '@mobileia/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@mobileia/authentication';
import { environment } from '../../environments/environment';
import { MIAFilter } from '@mobileia/layout-elite';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  /**
   * Almacena si se activo un filtro por compañia, para cuando se vuelve atras
   */
  public companyFilterSaved = -1;

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  public applyPercent(planId: number, type: string, percent: string, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestApplyPercent(token, planId, type, percent, callback);
    });
  }

  private requestApplyPercent(accessToken: string, planId: number, type: string, percent: string,  callback: (data: boolean) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/apply-percent',
    {access_token: accessToken, plan_id: planId, type: type, percent: percent }).subscribe(data => {
      if (data.success) {
          callback(true);
      }
  });
  }

  public copyAll(planId: number, companyId: number, newTitle: string, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestCopyAll(token, planId, companyId, newTitle, callback);
    });
  }

  private requestCopyAll(accessToken: string, planId: number, companyId: number, newTitle: string,  callback: (data: boolean) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/copy-all',
    {access_token: accessToken, plan_id: planId, company_id: companyId, title: newTitle }).subscribe(data => {
      if (data.success) {
          callback(true);
      }
  });
  }

  public copyPrices(planId: number, copyType: number, newType: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestCopyPrices(token, planId, copyType, newType, callback);
    });
  }

  private requestCopyPrices(accessToken: string, planId: number, copyType: number, newType: number,  callback: (data: boolean) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/copy-prices',
    {access_token: accessToken, plan_id: planId, affiliate_type_id: copyType, new_affiliate_type_id: newType }).subscribe(data => {
      if (data.success) {
          callback(true);
      }
  });
  }

  public create(plan: any, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      plan.access_token = token;
      this.requestCreate(plan, callback);
    });
  }

  private requestCreate(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plan/create', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editBenefits(planId : number, benefits : any[], callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestEditBenefits(token, planId, benefits, callback);
    });
  }

  private requestEditBenefits(accessToken : string, planId : number, benefits : any[],  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-benefits', {access_token: accessToken, plan_id: planId, benefits: benefits}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editQualification(planId : number, qualificationId : number, value : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestEditQualification(token, planId, qualificationId, value, callback);
    });
  }

  private requestEditQualification(accessToken : string, planId : number, qualificationId : number, value : number,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-qualification', {access_token: accessToken, plan_id: planId, qualification_id: qualificationId, value: value}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public edit(plan : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      plan.access_token = token;
      this.requestEdit(plan, callback);
    });
  }

  private requestEdit(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plan/edit', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public fetchById(planId: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchById(token, planId, callback);
  });
  }

  private requestFetchById(access_token: string, planId : number, callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/fetch', {access_token : access_token, id: planId}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editSpecialOffer(discount : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      discount.access_token = token;
      this.requestSpecialOffer(discount, callback);
    });
  }

  private requestSpecialOffer(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-special-offer', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editAge(discount : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      discount.access_token = token;
      this.requestEditAge(discount, callback);
    });
  }

  private requestEditAge(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-age', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editFamilyDiscount(discount : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      discount.access_token = token;
      this.requestEditFamilyDiscount(discount, callback);
    });
  }

  private requestEditFamilyDiscount(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-family-discount', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editAdditionalCostForFamilySize(marriage : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      marriage.access_token = token;
      this.requestEditAdditionalCostForFamilySize(marriage, callback);
    });
  }

  private requestEditAdditionalCostForFamilySize(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-additional-cost-for-family-size', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editAdditionalCost(marriage : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      marriage.access_token = token;
      this.requestEditAdditionalCost(marriage, callback);
    });
  }

  private requestEditAdditionalCost(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-additional-cost', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editMarriage(marriage : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      marriage.access_token = token;
      this.requestEditMarriage(marriage, callback);
    });
  }

  private requestEditMarriage(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-marriage', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editChild(child : any, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      child.access_token = token;
      this.requestEditChild(child, callback);
    });
  }

  private requestEditChild(params : any,  callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-child', params).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public editPerson(personId : number, planId : number, ageMin : number, ageMax: number, type: number, price: string, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestEditPerson(token, personId, planId, ageMin, ageMax, type, price, callback);
    });
  }

  private requestEditPerson(access_token: string, personId : number, planId : number, ageMin : number, ageMax: number, type: number, price: string, callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/edit-person', {access_token : access_token, id: personId, plan_id: planId, age_min: ageMin, age_max: ageMax, type: type, price: price}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public assignSanatorium(planId: number, sanatoriumId: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestAssignSanatorium(token, planId, sanatoriumId, callback);
    });
  }

  private requestAssignSanatorium(access_token: string, planId: number, sanatoriumId: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/assign-sanatorium', {
      access_token : access_token, plan_id: planId, sanatorium_id: sanatoriumId}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
  });
  }

  public assignScoring(planId: number, sanatoriumId: number,
    scoreInt: number, scoreExt: number, scoreGuard: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestAssignScoring(token, planId, sanatoriumId, scoreInt, scoreExt, scoreGuard, callback);
    });
  }

  private requestAssignScoring(
    access_token: string, planId: number, sanatoriumId: number,
    scoreInt: number, scoreExt: number, scoreGuard: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/assign-scoring', {
      access_token : access_token, plan_id: planId, sanatorium_id: sanatoriumId,
      score_int: scoreInt, score_ext: scoreExt, score_guard: scoreGuard}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
  });
  }

  public deleteSanatorium(planId: number, sanatoriumId: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteSanatorium(token, planId, sanatoriumId, callback);
    });
  }

  private requestDeleteSanatorium(access_token: string, planId: number, sanatoriumId: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/delete-sanatorium', {
      access_token : access_token, plan_id: planId, sanatorium_id: sanatoriumId}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
  });
  }

    /**
     * Devuelve todos los eventos asignado al usuario
     * @param callback 
     */
  public fetchAll(page : number, where : string, search : string, filter : MIAFilter, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
        this.requestFetchAll(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAll(access_token: string, page : number, where : string, search : string, filter : MIAFilter, callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/list', {access_token : access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search}).subscribe(data => {
        if(data.success){
            callback(data.response);
        }
    });
  }

  public deleteSpecialOffer(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_special_offer", callback);
  });
  }

  public deleteDiscountFamily(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_family_discount", callback);
  });
  }

  public deleteAdditionalCostFamily(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_additional_cost_for_family_size", callback);
  });
  }

  public deleteAdditionalCost(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_additional_cost", callback);
  });
  }

  public deleteChild(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_child", callback);
  });
  }

  public deleteMarriage(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_marriage", callback);
  });
  }

  public deletePerson(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_person", callback);
  });
  }

  public deleteAge(id : number, callback : (data: any)=> void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteItem(token, id, "plan_age", callback);
  });
  }

  private requestDeleteItem(access_token: string, id : number, type: string, callback : (data: any)=> void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plans/delete-item', {access_token : access_token, id: id, type: type}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
  });
  }

  public deletePlan(id: number, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDelete(token, id, callback);
  });
  }

  private requestDelete(access_token: string, id: number, callback: (data: any) => void){
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/plan/delete', {access_token : access_token, id: id}).subscribe(data => {
      if(data.success){
          callback(data.response);
      }
    });
  }
}
