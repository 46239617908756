import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@mobileia/authentication';
import { MIAFilter } from '@mobileia/layout-elite';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '@mobileia/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { }
  
  public save(params: any, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      params.access_token = token;
      this.requestSave(params, callback);
    });
  }

  private requestSave(params: any, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/form/save', params).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public fetchAllExport(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAllExport(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAllExport(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/form/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search, export: 1 
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public fetchAll(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAll(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAll(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/form/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search 
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public deleteContact(id: number, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteContact(token, id, callback);
  });
  }

  private requestDeleteContact(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/delete-form', 
    { access_token : access_token, id: id}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
    });
  }
}
