import { Component, OnInit } from '@angular/core';
import { MiaDynamicFormService } from '@mobileia/core';
import { FormGroup } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {

  form: FormGroup;
  company = null;
  
  public fields: any[] = [
    {
      type: 'text',
      name: 'name',
      label: 'Nombre',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'website',
      label: 'Website',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'url_video_institucional',
      label: 'Video Institucional',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'whatsapp_number',
      label: 'Whatsapp Number',
      value: '',
      required: false,
    },
    {
      type: 'button',
      name: 'save',
      label: 'Guardar'
    }
  ];

  constructor(private dynamicFormService : MiaDynamicFormService, 
    private router: Router,
    private companyService : CompanyService,
    private route: ActivatedRoute) {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idCompany = params['id'];
      if(idCompany != undefined){
        this.company = this.companyService.getCompanyById(idCompany);
        this.fields[0].value = this.company.name;
        this.fields[1].value = this.company.website;
        this.fields[2].value = this.company.url_video_institucional;
        this.fields[3].value = this.company.whatsapp_number;
      }

      // Preparamos formulario
      this.form = dynamicFormService.generateFormGroup(this.fields);
    });
  }

  ngOnInit() {
  }

  onSend(values){
    
    if(this.company == null){
      this.companyService.addNew(values.name, values.website, values.url_video_institucional, values.whatsapp_number, data => {
        this.router.navigate(['/companies']); 
      });
    }else{
      this.companyService.edit(this.company.id, values.name, values.website, values.url_video_institucional, values.whatsapp_number, data => {
        this.router.navigate(['/companies']); 
      });
    }
    
  }

}
