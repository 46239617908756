import { Component, OnInit } from '@angular/core';
import { MiaDynamicFormService } from '@mobileia/core';
import { FormGroup } from '@angular/forms';
import { CommentsService } from '../../services/comments.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogCommentsComponent } from '../blog-comments/blog-comments.component';
import { BlogComments } from 'src/app/entities/blog-comments';

@Component({
  selector: 'app-edit-blog-comment',
  templateUrl: './edit-blog-comment.component.html',
  styleUrls: ['./edit-blog-comment.component.scss']
})
export class EditBlogCommentComponent implements OnInit {

  newComment = new BlogComments();
  blog = null;
  
  constructor(private dynamicFormService : MiaDynamicFormService, 
    private router: Router,
    private commentService : CommentsService,
    private route: ActivatedRoute) {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idComment :number = params['id'];
      if(idComment != undefined){
        this.blog = this.commentService.getBlogCommentById(idComment, data =>{            
          this.newComment = data[0];
          //console.log(this.blog[0]);
        });
      }
      // Preparamos formulario
      //this.form = dynamicFormService.generateFormGroup(this.fields);
    });
  }

  ngOnInit() {
  }

  onClickSave(acept){
    if(this.newComment.response == null){ this.newComment.response = ''; }
    this.commentService.saveComment(this.newComment.id, this.newComment.comment, this.newComment.response, acept).subscribe(data => {
        if (data.success) {  
          this.router.navigate(['/blog-comments']);
        }
      }); 
  }
  onClickBack() {
    this.router.navigate(['/blog-comments']);
  }
}
