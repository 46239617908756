import {Injectable} from "@angular/core";
import {AuthenticationService} from "@mobileia/authentication";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken: string;

  constructor(private authenticationService: AuthenticationService) {
    this.requestToken();
  }

  public requestToken(): void {
    this.authenticationService.getAccessToken()
      .subscribe(token => this.accessToken = token)
  }

  public bodyHeaders(): object {
    return {
      headers: {
        access_token: this.accessToken,
      }
    }
  }
}
