import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '@mobileia/core';
import { Residency } from '../entities/residency';

@Injectable({
  providedIn: 'root'
})
export class ResidencyService extends MiaAuthHttpService {

  private residenceList: Residency[];

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public save(item: Residency): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/residency/save', item);
  }

  public fetchById(itemId: number): Observable<ApiResponse<Residency>> {
    return this.postAuthObject(environment.baseUrl + 'admin/residency/fetch', { id: itemId });
  }

  
  public fetchAll(): Observable<ApiResponse<Array<any>>> {
    console.log('Leyendo Residencias');
    return this.postAuthObject(environment.baseUrl + 'admin/residency/list', {});
  }
  

  public fetchAll2(callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAll(token, callback);
    });
  }

  private requestFetchAll(access_token: string, callback: (data: [any]) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'residency/list',{}).subscribe(data => {
      console.log('Data:' + data);
      if (data.success) {
        // Guardamos eventos cargados en el servicio
        this.residenceList = data.response;
        // enviamos datos
        
        callback(data.response);
      }
    });
  }
  
  // public fetchAll2(): Observable<Residency[]> {
  //   console.log('Por el servicio');
  //   return this.http.get(environment.javaBaseUrl + 'residency/all').pipe(
  //     map((res: any) => {
  //       this.residenceList = res.map((residency) => Residency.fromJsonObject(residency));
  //       return this.residenceList;
  //     })
  // }
}
