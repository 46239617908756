import { Component, OnInit } from '@angular/core';
import { ResidencyService } from 'src/app/services/residency.service';

@Component({
  selector: 'app-residency',
  templateUrl: './residency.component.html',
  styleUrls: ['./residency.component.scss']
})
export class ResidencyComponent implements OnInit {

  items = [];

  constructor(
    private residencyService: ResidencyService) { }

  ngOnInit() {
    this.loadItems();
  }

  loadItems() {
    this.residencyService.fetchAll().toPromise().then(data => {
      if (!data.success) {
        return;
      }
      this.items = data.response;
    });
  }

}
