import {Injectable} from "@angular/core";
import {EndpointsUrl} from "./endpointsUrl";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {ResidenceSellers, SellerCompanyFormModel} from "../../../entities/models/sellers/seller-company-form.model";
import {UpdateProbabilityForm} from "../../../entities/models/sellers/update-probability-form";

@Injectable({
  providedIn: 'root'
})
export class SellerCompanyService extends EndpointsUrl {
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super();
    this.endpoint = "sellercompany";
  }

  public postSellerCompany(sellerCompanyForm: SellerCompanyFormModel): Promise<string[]> {
    return this.httpClient
      .post<string[]>(this.createUrl(), sellerCompanyForm)
      .toPromise();
  }

  public deleteSellerCompany(companyId: number, residenceId: number, sellerId: number, type: SellerCompanyType): Promise<ResidenceSellers[]> {
    const route: string = SellerCompanyService.sellerTypeRoute('delete', type);
    return this.httpClient
      .put<ResidenceSellers[]>(
        this.otherUrl(route), {
          companyId: companyId,
          residenceId: residenceId,
          sellerId: sellerId
        })
      .toPromise();
  }

  public getAllSellersCompany(companyId: number, type: SellerCompanyType): Promise<ResidenceSellers[]> {
    const route: string = SellerCompanyService.sellerTypeRoute('company', type);
    return this.httpClient
      .get<ResidenceSellers[]>(this.otherUrl(`${route}/${companyId}`))
      .toPromise()
  }

  public updateProbabilities(form: UpdateProbabilityForm[], type: SellerCompanyType): Promise<ResidenceSellers[]> {
    const route: string = SellerCompanyService.sellerTypeRoute('probabilities', type);
    return this.httpClient
      .put<ResidenceSellers[]>(this.otherUrl(route), form)
      .toPromise()
  }

  private static sellerTypeRoute(route: string, type: SellerCompanyType): string {
    let result: string = `${route}-`;
    switch (type) {
      case SellerCompanyType.CONTACT:
        result += 'contact';
        break;
      case SellerCompanyType.FORM:
        result += 'form';
        break;
    }
    return result;
  }
}

//TODO NAMING MUST BE CHANGE AWAIT FOR BACK-END NEW MODELS
export enum SellerCompanyType {
  CONTACT, FORM
}
