import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SellerCompanyService, SellerCompanyType} from '../../../services/services/java/seller-company.service';
import {ResidenceSellers, SimpleSellerResponse} from "../../../entities/models/sellers/seller-company-form.model";
import {ToastrService} from "ngx-toastr";
import {UpdateProbabilityForm} from "../../../entities/models/sellers/update-probability-form";


@Component({
  selector: 'app-sellers-company',
  templateUrl: './sellers-company.component.html',
  styleUrls: ['./sellers-company.component.scss']
})
export class SellersCompanyComponent implements OnInit {
  companyId: number;
  companyName: string;
  title: string;
  residenceSellers: ResidenceSellers[] = [];
  isSellersFormType: boolean = true;
  isLoading: boolean = false;
  sellersIsEmpty: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sellerCompanyService: SellerCompanyService,
    private toastrService: ToastrService
  ) {
    this.route.queryParams.subscribe(params => {
      this.companyName = params.companyName;
      this.companyId = params.companyId;
      this.title = `${this.companyName} - Vendedores`;
      if (this.companyId === undefined) this.goBack();
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.requestSellers();
  }

  goToForm(): void {
    this.router.navigate(["/company/seller-company-form"], {
      queryParams: {
        companyId: this.companyId,
        companyName: this.companyName,
      }
    });
  }

  removeSellerFromResidence(residenceId: number, sellerId: number): void {
    const sellerType: SellerCompanyType = this.isSellersFormType ? SellerCompanyType.FORM : SellerCompanyType.CONTACT;
    this.sellerCompanyService.deleteSellerCompany(this.companyId, residenceId, sellerId, sellerType)
      .then(res => {
        this.residenceSellers = res;
        this.setSellerIsEmpty();
        this.toastrService.info("¡El vendedor ha sido desasignado!", "Solicitud de Eliminación")
      }).catch(error => this.toastrService.error("¡Error al querer remover al vendedor!", "Error"))
    ;
  }

  applyProb(prob: number, seller: SimpleSellerResponse): void {
    let num = prob > 0 ? prob : 0;
    num = num <= 100 ? num : 100;
    seller.probability = num;
  }

  switchSellersType(): void {
    this.isLoading = true;
    this.isSellersFormType = !this.isSellersFormType;
    this.requestSellers();
  }

  updateProbabilities(): void {
    let canUpdate = true;

    this.residenceSellers.forEach(companyResidency => {
      if (companyResidency.sellers.length > 0) {
        let total = 0;
        companyResidency.sellers.forEach(seller => total += seller.probability);
        if (total !== 100) {
          canUpdate = false;
        }
      }
    });

    if (canUpdate) {
      let probs: UpdateProbabilityForm[] = [];
      this.residenceSellers.forEach(r => {
        const sellerForms = r.sellers.map(s =>
          new UpdateProbabilityForm(this.companyId, s.probability, r.residenceId, s.id));
        probs = probs.concat(sellerForms);
      });
      const sellerType: SellerCompanyType = this.isSellersFormType ? SellerCompanyType.FORM : SellerCompanyType.CONTACT;
      this.sellerCompanyService.updateProbabilities(probs, sellerType)
        .then(res => {
          this.toastrService.success('¡Actualizadas con éxito!', 'Probabilidades');
        })
        .catch(error => this.toastrService.error('¡Revise que la suma de 100% por residencia!', 'Error'));
    } else
      this.toastrService.error('¡Revise que la suma de 100% por residencia!', 'Error');
  }

  goBack(): void {
    this.router.navigateByUrl("/companies");
  }

  setSellerIsEmpty(): void {
    const sellers: ResidenceSellers[] = this.residenceSellers.filter((residence: ResidenceSellers) => residence.sellers.length > 0);
    this.sellersIsEmpty = !(sellers.length > 0);
  }

  requestSellers(): void {
    const sellerType: SellerCompanyType = this.isSellersFormType ? SellerCompanyType.FORM : SellerCompanyType.CONTACT;
    this.sellerCompanyService
      .getAllSellersCompany(this.companyId, sellerType)
      .then(residenceSellers => {
        this.residenceSellers = residenceSellers;
        this.setSellerIsEmpty();
        this.isLoading = false;
        console.log('residenceSellers',residenceSellers);
      })
      .catch(() => this.toastrService.error('¡Hubo un error al traer los vendedores!', 'Error'))
  }
}
