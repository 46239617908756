import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { MIAFilter, LayoutMenuService, LayoutTableService } from '@mobileia/layout-elite';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/services/company.service';
import { FormService } from 'src/app/services/form.service';
import { AuthenticationService } from '@mobileia/authentication';
import { UserService } from 'src/app/services/user.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ResidencyService } from '../../services/residency.service';
import { AffiliateService } from '../../services/affiliate.service';
import { SellerService } from "../../services/services/java/seller.service";
import { StatusService } from 'src/app/services/status.service';
import {ToastrService} from "ngx-toastr";
import { Seller } from 'src/app/entities/models/sellers/seller';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  @ViewChild('exportSwal') private exportSwal: SwalComponent;

  fields: any;
  contacts: { last_page: 1, data: [any] };
  companies = [];
  plans = [];
  users = [];
  filter = new MIAFilter();
  filterCompany = -1;
  filterStatus = -1;
  filterId = '';
  filterFirstname = '';
  filterSurname = '';
  filterEmail = '';
  filterType = '-1';
  filterAge = '';
  filterDate: NgbDateStruct = null;
  filterDateFrom: NgbDateStruct = null;
  filterDateTo: NgbDateStruct = null;
  filterComment = '';
  pageCurrent = 1;
  pages = [];
  lastPage = 1;
  isLoading = true;
  where = '';
  search = '';
  selectedContact = null;
  residencies = [];
  affiliates = [];
  estatus = [];
  sellers: Seller[] = [];

  /**
   * Determina si el usuario que se logueo solo puede ver los pedidos de una sola prepaga
   */
  onlyCompany = false;
  columnsTable = [
    {title: 'ID', filter: 'id', class: 'text-nowrap'},
    {title: 'Prepaga', filter: 'form.company_id'},
    {title: 'Nombre', filter: 'form.firstname'},
    {title: 'Apellido', filter: 'form.lastname'},
    {title: 'Email', filter: 'form.email'},
    {title: 'Fecha', filter: 'form.created_at'},
    {title: 'Desde', filter: 'form.created_at'},
    {title: 'Hasta', filter: 'form.created_at'},
    {title: 'Estado', filter: 'form.status'},
    {title: 'Receptor', filter: 'form.receiver_user_id'},
    {title: 'Comentarios'},
    {title: 'CP', class: 'text-nowrap'},
    {title: 'Acciones', class: 'text-nowrap'}
  ];

  constructor(
    private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private companyService: CompanyService,
    private formService: FormService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private residencyService: ResidencyService,
    private affiliateService: AffiliateService,
    private userService: UserService,
    private sellerService: SellerService,
    private statusService: StatusService,
    private toastrService: ToastrService
    ){

    this.menuService.activatedSidebar(11);
  }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(user => {
      if (user == null) {
        return;
      }
      if (user.id != 3588) {
        this.onlyCompany = true;
        this.columnsTable.splice(5, 1);
      }
    });
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadCompanies();
    this.loadContacts();
    this.loadUsers();
    this.loadResidencies();
    this.loadAffiliate();
    this.loadStatus();
    this.loadSellers();
  }

  loadCompanies() {
    this.companyService.fetchAll(data => {
      this.companies = data;
    });
  }

  loadContacts() {
    this.isLoading = true;
    this.formService.fetchAll(this.pageCurrent, this.where, this.search, this.filter, response => {
      const pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i + 1));
      }
      this.pages = pages;
      this.lastPage = response.last_page;
      this.contacts = response;
      this.isLoading = false;
    });
  }

  onClickPage(page: number) {
    this.pageCurrent = page;
    this.loadContacts();
  }

  onRefresh(){
    window.location.reload();
  }

  onSendSearch() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    this.loadContacts();
  }

  onChangeWhere() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Iniciar busqueda
    this.where = '';
    if (this.filterId != '') {
      this.where = 'form.id:' + this.filterId;
    }
    if (this.filterFirstname != '') {
      this.where += ';form.firstname:like:' + this.filterFirstname;
    }
    if (this.filterSurname != '') {
      this.where += ';form.lastname:like:' + this.filterSurname;
    }
    if (this.filterEmail != '') {
      this.where += ';form.email:like:' + this.filterEmail;
    }
    if (this.filterCompany > 0) {
      this.where += ';form.company_id:' + this.filterCompany;
    }
    if (this.filterStatus > 0) {
      this.where += ';form.status:' + this.filterStatus;
    }
    if (this.filterDate != null) {
      this.where += ';form.created_at:date:' + this.filterDate.year + '-' + this.filterDate.month + '-' + this.filterDate.day;
    }
    if (this.filterDateFrom != null) {
      this.where += ';form.created_at:date_from:' + this.filterDateFrom.year + '-' + this.filterDateFrom.month + '-' + this.filterDateFrom.day;
    }
    if (this.filterDateTo != null) {
      this.where += ';form.created_at:date_to:' + this.filterDateTo.year + '-' + this.filterDateTo.month + '-' + this.filterDateTo.day;
    }
    if (this.filterComment != '') {
      this.where += ';form.comments:like:' + this.filterComment;
    }
    this.loadContacts();
  }

  onChangeFilter(newFilter) {
    // Verificar si se seleccinoo un filtro correcto
    if (newFilter.title == '' || newFilter.title == undefined) {
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadContacts();
  }

  getUserName(item) {
    for (const user of this.users) {
      if (user.id == item.receiver_user_id) {
        return user.firstname + ' ' + user.lastname;
      }
    }
    return '';
  }

  onExport() {
    this.exportSwal.showConfirmButton = false;
    this.exportSwal.showCloseButton = false;
    this.exportSwal.showCancelButton = false;
    this.exportSwal.allowEscapeKey = false;
    this.exportSwal.allowOutsideClick = false;
    this.exportSwal.show();

    this.formService.fetchAllExport(this.pageCurrent, this.where, this.search, this.filter, response => {
      response.data.map(t => t.residency_id = this.getResidencyName(t.residency_id));
      response.data.map(a => a.affiliate_type = this.getAffiliateName(a.affiliate_type));
      response.data.map(s => s.status = this.getStatusName(s.status));
      response.data.map(s => s.receiver_seller_id = this.getSellerEmalil(s.receiver_seller_id));
      this.exportSwal.nativeSwal.close();
      response.data.forEach(element => {
        delete element['company_id'];
        delete element['receiver_user_id'];
        delete element['deleted'];
        delete element['api_sended'];
        delete element['api_sended'];
        delete element['seller_email'];
      });
       new Angular5Csv(response.data, 'formularios', {
          fieldSeparator: ';',
          //quoteStrings: '"',
          //decimalseparator: '.',
          showLabels: true,
          //showTitle: true,
          //title: 'Your title',
          //useBom: true,
          //noDownload: true,
          headers: ["ID",
           "Company Name", 
           "First Name", 
           "Last Name",
           "Email",
           "Phone", 
           "Status",
           "Age",
           "Spouse Age",
           "Number of children",
           "Residency",
           "Affiliate Type",
           "Type",
           "Comment",
           "Created At",
           "Updated At",
           "Seller Email",
           "CP",
           "DNI",
           "First Url",
           "Fuente"
          ]

       });
    });
  }

  getResidencyName(residency_id: any) {
    for (let i = 0; i < this.residencies.length; i++) {
      if (this.residencies[i].id === residency_id) {
        return this.residencies[i].name;
      }
    }
  }

  loadResidencies() {
    this.residencyService.fetchAll2(data => {
      this.residencies = data;
    });
  }

  getAffiliateName(affiliate_id: any) {
    for (let i = 0; i < this.affiliates.length; i++) {
      if (this.affiliates[i].id === affiliate_id) {
        return this.affiliates[i].name;
      }
    }
  }

  getSellerEmalil(seller_id: any) {
    for (let i = 0; i < this.sellers.length; i++) {
      if (this.sellers[i].id === seller_id) {
        return this.sellers[i].mail;
      }
    }
  }


  loadAffiliate() {
    this.affiliateService.fetchAll().toPromise().then(data => {
      if(!data.success) {
        return;
      }
      this.affiliates = data.response;
    });
    
  }

  getStatusName(status_id: any) {
    for (let i = 0; i < this.estatus.length; i++) {
      if (this.estatus[i].id === status_id) {
        return this.estatus[i].name;
      }
    }
  }

  loadStatus() {
    this.statusService.fetchAll().toPromise().then(data => {
      if(!data.success) {
        return;
      }
      this.estatus = data.response;
    });
    
  }

  loadSellers(){
    this.sellerService.getAll()
    .then(res => this.sellers = res)
    .catch(() => this.toastrService.error("¡Error al solicitar vendedores!", "Error"));
  }


  removeItem(item) {
    this.formService.deleteContact(item.id, data => {});
    let indexOf = 0;
    for (let i = 0; i < this.contacts.data.length; i++) {
      if (this.contacts.data[i].id === item.id) {
        indexOf = i;
        break;
      }
    }
    this.contacts.data.splice(indexOf, 1);
  }

  onSaveContact() {
    this.formService.save(this.selectedContact, data => {
      console.log('se guardo');
    });
  }

  open(content, item) {
    console.log(item.analytics_obj);
    this.residencyService.fetchById(item.residency_id).toPromise().then(data => {
      item.residency = data.response.name;
      if (item.affiliate_type !== 0) {
        this.affiliateService.fetchById(item.affiliate_type).toPromise().then(info => {
          item.affiliate = info.response.name;
          this.selectedContact = item;
          console.log('item '+ item.first_url);
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            if (result === '100') {
              this.onSaveContact();
            }
          }, (reason) => {
          });
          return false;
        });
      } else {
        this.selectedContact = item;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          if (result === '100') {
            this.onSaveContact();
          }
        }, (reason) => {
        });
        return false;
      }
    });
    if(item.receiver_seller_id != undefined){
      this.sellerService.getSeller(item.receiver_seller_id)
    .then(seller => {
            this.fields = seller.mail;
          });
    } else {
      this.fields = "N/A";
    }
    
  }

  loadUsers() {
    this.userService.fetchAll().subscribe(data => {
      if (data.success) {
        this.users = data.response;
      }
    });
  }
}
