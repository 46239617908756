import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'affiliateType',
  pure: false
})
export class AffiliateTypePipe implements PipeTransform {

  transform(items: any, filter?: any): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.affiliate_type_id === filter);
  }

}
