import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanService } from '../../services/plan.service';
import { SanatoriumService } from '../../services/sanatorium.service';
import { Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { MIAFilter } from '@mobileia/layout-elite';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sanatorium-plan',
  templateUrl: './sanatorium-plan.component.html',
  styleUrls: ['./sanatorium-plan.component.scss']
})
export class SanatoriumPlanComponent implements OnInit {

  // Almacenamos el plan abierto, child_age_parent : false
  plan = {id: -1, name: '', sanatorium: []};
  // Guardamos todos los sanatorios para poder seleccionar
  sanatoriums = [];
  isLoading = true;
  showAdd = false;
  textQuery = null;
  selectedSanatorium = { id: 1, name: 'Sanatorio', pivot: {
    score_int: 0,
    score_ext: 0,
    score_guard: 0
  } };

  constructor(private route: ActivatedRoute,
    private planService: PlanService,
    private sanatoriumService: SanatoriumService,
    private modalService: NgbModal
  ) {
    // Cargar los sanatorios
    this.loadSanatoriums();
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      const idPlan = params['id'];
      if (idPlan !== undefined) {
        // Buscar plan en el server
        this.planService.fetchById(idPlan, data => {
          this.isLoading = false;
          this.plan = data;
        });
      }
    });
   }

  ngOnInit() {
  }

  assignSanatorium() {
    if (this.textQuery == null) {
      return false;
    }
    this.planService.assignSanatorium(this.plan.id, this.textQuery.id, data => {
      this.plan.sanatorium.unshift(this.textQuery);
      this.textQuery = null;
    });
    return false;
  }

  assignScoring() {
    this.planService.assignScoring(this.plan.id, this.selectedSanatorium.id,
      this.selectedSanatorium.pivot.score_int, this.selectedSanatorium.pivot.score_ext,
      this.selectedSanatorium.pivot.score_guard, data => {

    });
    return false;
  }

  removeItem(item) {
    this.planService.deleteSanatorium(this.plan.id, item.id, data => {});
    this.plan.sanatorium.splice(this.plan.sanatorium.indexOf(item), 1);
  }

  loadSanatoriums() {
    this.sanatoriumService.fetchAll(1, '', '', new MIAFilter(), 500, response => {
      this.sanatoriums = response.data;
    });
  }

  open(content, item) {
    this.selectedSanatorium = item;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result == '100') {
        this.assignScoring();
      }
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return false;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (term.length < 2) {
          return [];
        } else {
          return this.sanatoriums.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10);
        }
      }
        // term.length < 2 ? []
        // : this.sanatoriums.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    ))

    formatter = (x: {name: string}) => x.name;
}
