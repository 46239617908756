import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutMenuService, LayoutTableService, MIAFilter } from '@mobileia/layout-elite';
import { ContactsService } from '../../services/contacts.service';
import { CompanyService } from '../../services/company.service';
import { last } from '@angular/router/src/utils/collection';
import { PlanService } from 'src/app/services/plan.service';
import {NgbModal, ModalDismissReasons, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { AuthenticationService } from '@mobileia/authentication';
import { UserService } from 'src/app/services/user.service';
import { SellerService } from "../../services/services/java/seller.service";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  @ViewChild('exportSwal') private exportSwal: SwalComponent;

  fields: any;
  contacts: { last_page: 1, data: [any] };
  companies = [];
  plans = [];
  users = [];
  filter = new MIAFilter();
  filterCompany = -1;
  filterStatus = -1;
  filterPlan = -1;
  filterId = '';
  filterFirstname = '';
  filterSurname = '';
  filterType = '-1';
  filterAge = '';
  filterDate: NgbDateStruct = null;
  filterDateFrom: NgbDateStruct = null;
  filterDateTo: NgbDateStruct = null;
  filterComment = '';
  pageCurrent = 1;
  pages = [];
  lastPage = 1;
  isLoading = true;
  where = '';
  search = '';
  selectedContact = null;
  /**
   * Determina si el usuario que se logueo solo puede ver los pedidos de una sola prepaga
   */
  onlyCompany = false;
  columnsTable = [
    {title: 'ID', filter: 'id', class: 'text-nowrap'},
    {title: 'Nombre', filter: 'contact_requests.contact'},
    {title: 'Apellido', filter: 'contact_requests.contact'},
    {title: 'Tipo', filter: 'contact_requests.contact'},
    {title: 'Edad', filter: 'contact_requests.contact'},
    {title: 'Prepaga', filter: 'contact_requests.contact'},
    {title: 'Plan', filter: 'contact_requests.contact'},
    {title: 'Cuota', filter: 'contact_requests.contact'},
    {title: 'Estado', filter: 'contact_requests.contact'},
    {title: 'Fecha', filter: 'contact_requests.created_at'},
    {title: 'Fecha Desde', filter: 'contact_requests.created_at'},
    {title: 'Fecha Hasta', filter: 'contact_requests.created_at'},
    {title: 'Comentarios'},
    {title: 'CP', class: 'text-nowrap'},
    {title: 'Acciones', class: 'text-nowrap'}
  ];

  constructor(
    private menuService: LayoutMenuService,
    private tableService: LayoutTableService,
    private companyService: CompanyService,
    private contactService: ContactsService,
    private planService: PlanService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private userService: UserService,
    private sellerService: SellerService 
    ) {

    this.menuService.activatedSidebar(5);
  }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(user => {
      if (user == null) {
        return;
      }
      /*
      if (user.id != 3588) {
        this.onlyCompany = true;
        this.columnsTable.splice(4, 1);
      }*/
    });
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadCompanies();
    this.loadPlans();
    this.loadContacts();
    this.loadUsers();
  }

  loadCompanies() {
    this.companyService.fetchAll(data => {
      this.companies = data;
    });
  }

  loadPlans() {
    const filter = new MIAFilter();
    filter.title = 'company_id';
    this.planService.fetchAll(1, '', '', filter, response => {
      this.plans = response.data;
    });
  }

  loadContacts() {
    this.isLoading = true;
    console.log('This.where: ' + this.where);
    this.contactService.fetchAll(this.pageCurrent, this.where, this.search, this.filter, response => {
      const pages = [];
      for (let i = 0; i < response.last_page; i++) {
        pages.push((i + 1));
      }
      console.log('response '+ response);
      this.pages = pages;
      this.lastPage = response.last_page;
      this.contacts = response;
      this.isLoading = false;
    });
    console.log('response Segundo '+ this.contacts);
  }

  onClickPage(page: number) {
    this.pageCurrent = page;
    this.loadContacts();
  }

  onSendSearch() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    this.loadContacts();
  }

  onChangeWhere() {
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Iniciar busqueda
    this.where = '';
    if (this.filterId != '') {
      this.where = 'contact_requests.id:' + this.filterId;
    }
    if (this.filterFirstname != '') {
      this.where += ';contact_requests.firstname:like:' + this.filterFirstname;
    }
    if (this.filterSurname != '') {
      this.where += ';contact_requests.surname:like:' + this.filterSurname;
    }
    if (this.filterCompany > 0) {
      this.where += ';contact_requests.company_id:' + this.filterCompany;
    }
    if (this.filterPlan > 0) {
      this.where += ';contact_requests.plan_id:' + this.filterPlan;
    }
    if (this.filterStatus > 0) {
      this.where += ';contact_requests.status_id:' + this.filterStatus;
    }
    if (this.filterType != '-1') {
      this.where += ';contact_requests.affiliate_type_real:' + this.filterType;
    }
    if (this.filterAge != '') {
      this.where += ';log_search.main_age:' + this.filterAge;
    }
    if (this.filterDate != null) {
      this.where += ';contact_requests.created_at:date:' + this.filterDate.year + '-' + this.filterDate.month + '-' + this.filterDate.day;
    }
    if (this.filterDateFrom != null) {
      this.where += ';contact_requests.created_at:date_from:' + this.filterDateFrom.year + '-' + this.filterDateFrom.month + '-' + this.filterDateFrom.day;
    }
    if (this.filterDateTo != null) {
      this.where += ';contact_requests.created_at:date_to:' + this.filterDateTo.year + '-' + this.filterDateTo.month + '-' + this.filterDateTo.day;
    }
    if (this.filterComment != '') {
      this.where += ';contact_requests.comments:like:' + this.filterComment;
    }
    console.log(this.where);
    this.loadContacts();
  }

  onChangeFilter(newFilter) {
    // Verificar si se seleccinoo un filtro correcto
    if (newFilter.title == '' || newFilter.title == undefined) {
      return;
    }
    // Asignamos nuevo filtro
    this.filter = newFilter;
    // Reiniciar paginas
    this.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadContacts();
  }

  onExport() {
    this.exportSwal.showConfirmButton = false;
    this.exportSwal.showCloseButton = false;
    this.exportSwal.showCancelButton = false;
    this.exportSwal.allowEscapeKey = false;
    this.exportSwal.allowOutsideClick = false;
    this.exportSwal.show();

    this.contactService.fetchAllExport(this.pageCurrent, this.where, this.search, this.filter, response => {
      this.exportSwal.nativeSwal.close();
      new Angular5Csv(response.data, 'pedidos', {
        fieldSeparator: ';',
        //quoteStrings: '"',
        //decimalseparator: '.',
        //showLabels: true,
        //showTitle: true,
        //title: 'Your title',
        //useBom: true,
        //noDownload: true,
        //headers: ["First Name", "Last Name", "ID"]
      });
    });
  }

  removeItem(item) {
    this.contactService.deleteContact(item.id, data => {});
    var indexOf = 0;
    for (var i = 0; i < this.contacts.data.length; i++) {
      if (this.contacts.data[i].id == item.id) {
        indexOf = i;
        break;
      }
    }
    this.contacts.data.splice(indexOf, 1);
  }

  onSaveContact() {
    this.contactService.save(this.selectedContact, data => {
      console.log('se guardo');
    });
  }

  open(content, item) {
    console.log(item.analytics_obj);
    this.selectedContact = item;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result == '100') {
        this.onSaveContact();
      }
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    // console.log('item.receiver_seller_id--- '+ item.receiver_seller_id);
    console.log('item.cp--- '+ item.cp);
    console.log('item.receiver_json--- '+ JSON.stringify(item));
    if(item.receiver_seller_id != undefined){
      this.sellerService.getSeller(item.receiver_seller_id)
    .then(seller => {
            this.fields = seller.mail;
          });
    } else {
      this.fields = "N/A";
    }
    return false;
  }

  openSearch(item) {
    let url = 'https://www.elegimejor.com.ar/';
    url += 'comparar-medicina-prepaga?key_agent=El23.AS64dS';
    url += '&sid=' + item.log_search_id;
    url += '&n=' + item.firstname + ' ' + item.surname;
    url += '&em=' + item.email;
    url += '&ph=' + item.contact_obj.phone;
    url += '&residencia=' + item.residency;
    url += '&tipo=' + item.affiliate_type;

    let dateChilds = [];
    if (item.children_ages) {
      dateChilds = item.children_ages.replace('[', '').replace(']', '').split(',');
    }

    if (dateChilds.length > 0 && (item.spouse_age == undefined || item.spouse_age <= 0)) {
      url += '&cobertura=4';
    } else if ((item.spouse_age == undefined || item.spouse_age <= 0) && dateChilds.length == 0) {
      url += '&cobertura=1';
    } else if (item.spouse_age > 0 && item.children_ages == undefined) {
      url += '&cobertura=2';
      url += '&conyuge=' + item.spouse_age;
    } else {
      url += '&cobertura=3';
      url += '&conyuge=' + item.spouse_age;
    }

    if (dateChilds.length > 0) {
      url += '&hijos=' + dateChilds.length;
      url += '&edad_hijos=' + item.children_ages.replace('[', '').replace(']', '');
    }

    url += '&edad=' + item.main_age;

    if (item.utm_source) {
      url += '&utm=' + item.utm_source;
    }

    if (item.first_url) {
      url += '&url=' + item.first_url;
    }

    window.open(url);
  }

  loadUsers() {
    this.userService.fetchAll().subscribe(data => {
      if (data.success) {
        this.users = data.response;
      }
    });
  }
}
