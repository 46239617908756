import {Seller} from "./seller";

export class SellerModel {
  constructor(
    public readonly name: string,
    public readonly surname: string,
    public readonly mail: string,
    public readonly ccMails ?: string[],
    public readonly id?: number,
  ) {
  }
}

export const sellerFromJson = (json: any): SellerModel => {
  return new SellerModel(
    json.id,
    json.name,
    json.surname,
    json.mail,
    json.mails,
  )
}

export const sellerToJson = (seller: SellerModel): Seller => {
  return {
    id: seller.id,
    name: seller.name,
    surname: seller.surname,
    mail: seller.mail,
    companies: [],
    ccMails: seller.ccMails,
  }
}
