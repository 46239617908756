// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //URLs de PRODUCCIÓN
  baseUrl: 'https://elegimejor-api.appspot.com/',
  javaBaseUrl: 'https://elegimejor-java-dot-elegimejor-api.appspot.com/',

  //URLs de STAGING
  
  //javaBaseUrl: 'http://localhost:8080/',
  //baseUrl: 'https://20220426t194439-dot-em-apis-staging-348420.uc.r.appspot.com/',
  //baseUrl: 'https://20220429t150212-dot-em-apis-staging-348420.uc.r.appspot.com/',
  //javaBaseUrl: 'https://2-dot-elegimejor-java-dot-em-apis-staging-348420.uc.r.appspot.com/'

  //URLs de Local Staging
  //javaBaseUrl: 'https://127.0.0.1:8080/',
  //baseUrl: 'http://127.0.0.1:8080/',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
