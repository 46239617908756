import { Component, OnInit } from '@angular/core';
import { Doctor } from 'src/app/entities/doctor';
import { Address } from 'src/app/entities/address';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { DoctorService } from 'src/app/services/doctor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Speciality } from 'src/app/entities/speciality';
import { Prepaga } from 'src/app/entities/prepaga';
import { Plan } from 'src/app/entities/plan';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.scss']
})
export class AddDoctorComponent implements OnInit {

  doctorId = -1;
  newDoctor = new Doctor();
  selectedSpeciality: Speciality = null;
  selectedPrepaga: Prepaga = null;

  constructor(
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private router: Router
  ) { }

  ngOnInit() {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      this.doctorId = params['id'];
      if (this.doctorId !== undefined) {
        this.loadDoctor();
      }
    });
  }

  onClickSave() {
    this.doctorService.save(this.newDoctor.id, this.newDoctor.name, this.newDoctor).subscribe(data => {
      if (data.success) {
        this.router.navigate(['/cartilla']);
      }
    });
  }

  loadDoctor() {
    this.doctorService.fetchById(this.doctorId).subscribe(data => {
      if (data.success) {
        this.newDoctor = data.response;
        this.newDoctor.remove_addresses = [];

        this.loadPlans();
      }
    });
  }

  loadPlans() {
    this.newDoctor.prepagas.forEach(prepaga => {
      prepaga.plans.forEach(plan => {
        if (this.isPlanSelected(plan.id)) {
          plan.is_selected = 1;
        } else {
          plan.is_selected = 0;
        }
      });
    });
  }

  isPlanSelected(planId: number): boolean {
    for (const key in this.newDoctor.plans) {
      if (this.newDoctor.plans.hasOwnProperty(key)) {
        const plan = this.newDoctor.plans[key];
        if (plan.id == planId) {
          return true;
        }
      }
    }

    return false;
  }

  handleAddressChange(google: GoogleAddress, address: Address) {
    address.lat = google.geometry.location.lat();
    address.lng = google.geometry.location.lng();
    address.place = google.id;
    console.log(google);
    // Guardamos datos obtenidos
    // this.sanatorium.address = address.formatted_address;
    //this.sanatorium.lat = address.geometry.location.lat();
    //this.sanatorium.lng = address.geometry.location.lng();
  }

  addAddress() {
    this.newDoctor.addresses.push(new Address());
  }

  deleteAddress(address: Address) {
    let index = this.newDoctor.addresses.indexOf(address, 0);
    if (index > -1) {
      this.newDoctor.remove_addresses.push(address);
      this.newDoctor.addresses.splice(index, 1);
    }
    if (this.newDoctor.addresses.length == 0) {
      this.addAddress();
    }
  }

  addSpeciality() {
    if (this.selectedSpeciality == null) {
      return;
    }

    this.newDoctor.specialities.push(this.selectedSpeciality);
    this.selectedSpeciality = null;
  }

  deleteSpeciality(speciality: Speciality) {
    let index = this.newDoctor.specialities.indexOf(speciality, 0);
    if (index > -1) {
      this.newDoctor.specialities.splice(index, 1);
    }
  }

  /** Busquede de especialidades **/
  formatterSpecialities = (x: Speciality) => x.name;

  searchSpecialities = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? []
        : this.doctorService.fetchSpecialitiesByQuery(term).pipe(map(data => {
          if (data.success) {
            return data.response;
          } else {
            return [];
          }
        })))
    )

    /** Busquede de prepagas **/
  formatterPrepagas = (x: Prepaga) => x.name;

  searchPrepagas = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? []
        : this.doctorService.fetchPrepagasByQuery(term).pipe(map(data => {
          if (data.success) {
            return data.response;
          } else {
            return [];
          }
        })))
    )

    addPrepaga() {
      if (this.selectedPrepaga == null) {
        return;
      }

      this.newDoctor.prepagas.push(this.selectedPrepaga);
      this.selectedPrepaga = null;
    }

    deletePrepaga(prepaga: Prepaga) {
      this.deletePlansByPrepagaId(prepaga.id);

      let index = this.newDoctor.prepagas.indexOf(prepaga, 0);
      if (index > -1) {
        this.newDoctor.prepagas.splice(index, 1);
      }
    }

    deletePlansByPrepagaId(prepagaId: number) {
      this.newDoctor.plans.forEach(plan => {
        if (plan.id_prepaga == prepagaId) {
          this.deletePlan(plan);
        }
      });
    }

    onChangePlan(plan: Plan) {
      if (plan.is_selected == 1) {
        this.addPlan(plan);
      } else {
        this.deletePlan(plan);
      }
    }

    addPlan(plan: Plan) {
      if (this.isPlanSelected(plan.id)) {
        return;
      }
      this.newDoctor.plans.push(plan);
    }

    deletePlan(plan: Plan) {
      let planInternal = this.newDoctor.plans.find(p => p.id == plan.id);
      if (planInternal == null || planInternal == undefined) {
        return;
      }
      let index = this.newDoctor.plans.indexOf(planInternal, 0);
      if (index > -1) {
        this.newDoctor.plans.splice(index, 1);
      }
    }

    onClickBack() {
      this.router.navigate(['/cartilla']);
    }
}
