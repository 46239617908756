import { Injectable } from '@angular/core';
import { ApiResponse } from '@mobileia/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@mobileia/authentication';
import { environment } from '../../environments/environment';
import { MIAFilter } from '@mobileia/layout-elite';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  public save(params: any, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      params.access_token = token;
      this.requestSave(params, callback);
    });
  }

  private requestSave(params: any, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/contact/save', params).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public savePopup(params: any, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      params.access_token = token;
      this.requestSavePopup(params, callback);
    });
  }

  private requestSavePopup(params: any, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/popup/save', params).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public fetchAllExport(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAllExport(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAllExport(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/contact/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search, export: 1
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public fetchAll(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    console.log('dentro del servicio 1 ');
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAll(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAll(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/contact/list', {
      access_token: access_token,
      filter: filter.title,
      asc: filter.asc,
      page: page,
      where: where,
      search: search,
      user_id: this.authService.currentUser.getValue().id
    }).subscribe(data => {
      console.log('Respuesta de la API:');
      console.log(data);
      if (data.success) {
        callback(data.response);
      }
    });
  }





  public fetchAllExportPopup(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAllExportPopUp(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAllExportPopUp(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/popup/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search, export: 1
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public fetchAllPopup(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAllPopup(token, page, where, search, filter, callback);
    });
  }

  private requestFetchAllPopup(access_token: string,
    page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/popup/list', {
      access_token: access_token, filter: filter.title, asc: filter.asc, page: page, where: where, search: search
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  public deleteContact(id: number, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteContact(token, id, callback);
  });
  }

  private requestDeleteContact(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/delete-contact',
    { access_token : access_token, id: id}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
    });
  }

  public deletePopup(id: number, callback: (data: any) => void){
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeletePopup(token, id, callback);
  });
  }

  private requestDeletePopup(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/delete-popup',
    { access_token : access_token, id: id}).subscribe(data => {
      if (data.success) {
          callback(data.response);
      }
    });
  }
}
