import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse, MIATableModel, ApiPagination } from '@mobileia/core';
import { Observable } from 'rxjs';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';

@Injectable({
  providedIn: 'root'
})
export class BlogService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public save(item: any): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/blog/save', item);
  }

  public fetchBySlug(fetch: number): Observable<ApiResponse<any>> {
    return this.postAuthObject(environment.baseUrl + 'blog/fetch', { slug: fetch });
  }

  public fetchAll(params: MIATableModel): Observable<ApiResponse<ApiPagination<any>>> {
    return this.postAuthObject(environment.baseUrl + 'admin/blog/list', params);
  }

  public fetchLast(params: MIATableModel): Observable<ApiResponse<Array<any>>> {
    return this.postAuthArray(environment.baseUrl + 'blog/last', params);
  }

  public saveRelation(item: any): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/post-relation/save', item);
  }

  public fetchAllRelation(slug): Observable<ApiResponse<Array<any>>> {
    return this.postAuthArray(environment.baseUrl + 'admin/post-relation/list', {slug: slug});
  }

  public removeRelation(itemId: any): Observable<ApiResponse<boolean>> {
    return this.postAuthObject(environment.baseUrl + 'admin/post-relation/remove', {id: itemId});
  }
}
