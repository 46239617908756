import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MiaDynamicFormService} from "@mobileia/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SellerService} from "../../../services/services/java/seller.service";
import {SellerModel} from "../../../entities/models/sellers/seller.model";
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-seller-form',
  templateUrl: './seller-form.component.html',
  styleUrls: ['./seller-form.component.scss']
})
export class SellerFormComponent implements OnInit {
  sellerId: number;
  formGroup: FormGroup;
  fields: any[] = [
    {
      type: 'text',
      name: 'name',
      label: 'Nombre',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'surname',
      label: 'Apellido',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'mail',
      label: 'Email',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'secondMail',
      label: '#1 CC Email (Opcional)',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'thirdMail',
      label: '#2 CC Email (Opcional)',
      value: '',
      required: false,
    },
    {
      type: 'button',
      name: 'save',
      label: 'Guardar'
    }
  ]

  constructor(
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private dynamicFormService: MiaDynamicFormService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(param => this.assignSeller(param['sellerId']))
  }

  ngOnInit() {
  }

  submit(formValues): void {
    const sellerForm: SellerModel = new SellerModel(
      formValues.name,
      formValues.surname,
      formValues.mail,
      [formValues.secondMail, formValues.thirdMail],
      this.sellerId
    );

    if (this.sellerId !== undefined) {
      this.sellerService.putSeller(sellerForm)
        .then(() => {
          this.toastrService.success("¡El vendedor ha sido actualizado!","Solicitud de Actualización");
          this.goToSellers();
        })
        .catch(() => {
          this.toastrService.error("¡Hubo un error!","Solicitud de Actualización");
        });
    } else {
      this.sellerService.postSeller(sellerForm)
        .then(() => {
          this.toastrService.success("¡El vendedor ha sido creado!","Solicitud de Creación");
          this.goToSellers();
        })
        .catch(response => {
          this.toastrService.error(response.error.message,"Error al crear vendedor");
        });
    }
  }

  goToSellers(): void {
    this.router.navigate(["/sellers"])
  }

  private assignSeller(sellerId: number): void {
    if (sellerId === undefined) {
      this.generateFormGroup();
    } else {
      this.sellerService.getSeller(sellerId)
        .then(seller => {
          this.sellerId = sellerId;
          this.fields[0].value = seller.name;
          this.fields[1].value = seller.surname;
          this.fields[2].value = seller.mail;
          this.fields[3].value = seller.ccMails[0];
          this.fields[4].value = seller.ccMails[1];
          this.generateFormGroup();
        });
    }
  }

  private generateFormGroup(): void {
    this.formGroup = this.dynamicFormService.generateFormGroup(this.fields);
  }
}
