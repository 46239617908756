import {Injectable} from '@angular/core';
import {ApiResponse} from '@mobileia/core';
import {HttpClient} from '@angular/common/http';
import {MiaAuthHttpService, AuthenticationService} from '@mobileia/authentication';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';
import { BlogComments } from '../entities/blog-comments';


@Injectable({
  providedIn: 'root'
})
export class CommentsService extends MiaAuthHttpService {
  [x: string]: any;

  private comments = [];

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  /**
   * Devuelve el listado de resultdo
   * @param callback
   */

  
  //Función para obtener un comentario del blog por id
  public getBlogCommentById(id:number, callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestBlogCommentById(token, id, callback);
    });
  }
  
  //Función para lista opiniones en status 0 de prepagas u obras sociales
  public fetchAll(callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAll(token, callback);
    });
  }

  //Función para lista opiniones en status 0 del blog
  public fetchAllBlog(callback: (data: [any]) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestFetchAllBlog(token, callback);
    });
  }

  //función para aceptar opinión de prepaga u obra social
  public aceptCompanyComment(id: number, callback: (data: any) => void) {
      this.authService.getAccessToken().subscribe(token => {
      this.requestAcept(token, id, callback);
      });
  }

  //función para eliminar opinión de prepaga u obra social
  public deleteCompanyComment(id: number, callback: (data: any) => void) {
      this.authService.getAccessToken().subscribe(token => {
        this.requestDelete(token, id, callback);
      });
  }

  //función para aceptar opinión del blog
  public aceptBlogComment(id: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
    this.requestAceptBlog(token, id, callback);
    });
  }

  //función para eliminar opinión del blog
  public deleteBlogComment(id: number, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
      this.requestDeleteBlog(token, id, callback);
    });
  }

  //funciones request para obtener una opinión del blog por id

  private requestBlogCommentById(access_token: string, id:number, callback: (data: [any]) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'blog/get-post-by-id', {access_token: access_token, id: id}).subscribe(data => {
      if (data.success) {
        // Guardamos eventos cargados en el servicio
        this.comments = data.response;
        //console.log(this.comments);
        // enviamos datos
        callback(data.response);
      }
    });
}

  //funciones request para listar opiniones de prepagas u obras sociales

  private requestFetchAll(access_token: string, callback: (data: [any]) => void) {
      this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/company-comments', {access_token: access_token}).subscribe(data => {
        if (data.success) {
          // Guardamos eventos cargados en el servicio
          this.comments = data.response;
          //console.log(this.comments);
          // enviamos datos
          callback(data.response);
        }
      });
  }

  //funciones request para listar opiniones del blog

  private requestFetchAllBlog(access_token: string, callback: (data: [any]) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'blog/blog-comments', {access_token: access_token}).subscribe(data => {
      if (data.success) {
        // Guardamos eventos cargados en el servicio
        this.comments = data.response;
        //console.log(this.comments);
        // enviamos datos
        callback(data.response);
      }
    });
  }

    /*
    private requestEdit(access_token: string, id: number, company_id: number, name: string, email: string, comment: string, status: number,
      update_at: string, callback: (data: any) => void) {
      this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'admin/company-edit-comment', {
        access_token: access_token,
        id: id,
        name: name,
        email: email,
        comment: comment,
        status: status,
        updated_at: update_at,
      }).subscribe(data => {
        if (data.success) {
          callback(data.response);
        }
      });
    }
  */
    
  //funciones request para aceptar opiniones de prepagas u obras sociales

  private requestAcept(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'company-comments/acept-comment', {
      access_token: access_token,
      id: id
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  //funciones request para aceptar opiniones del blog

  private requestAceptBlog(access_token: string, id: number, callback: (data: any) => void) {
    this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'blog/acept-comment', {
      access_token: access_token,
      id: id
    }).subscribe(data => {
      if (data.success) {
        callback(data.response);
      }
    });
  }

  //----Funciones request para editar opiniones del blog ------------

  public editBlogComment(blogId: number, comment: string, callback: (data: any) => void) {
      this.authService.getAccessToken().subscribe(token => {
        this.requestEditBlogComment(token, blogId, comment, callback);
      });
    }
  
    private requestEditBlogComment(accessToken: string, blogId: number, comment: string, callback: (data: boolean) => void) {
      this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'blog-comment/edit',
        {access_token: accessToken, blogId: blogId, comment: comment ,}).subscribe(data => {
        if (data.success) {
          callback(true);
        }
      });
    }

    //----------------------------------------------------------------
  //funciones request para eliminar opiniones de prepagas u obras sociales

  private requestDelete(access_token: string, id: number, callback: (data: any) => void) {
      this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'company-comments/delete-comment', {
        access_token: access_token,
        id: id
      }).subscribe(data => {
        if (data.success) {
          callback(data.response);
        }
      });
    }

  //funciones request para eliminar opiniones del blog

  private requestDeleteBlog(access_token: string, id: number, callback: (data: any) => void) {
      this.http.post<ApiResponse<[any]>>(environment.baseUrl + 'blog/delete-comment', {
        access_token: access_token,
        id: id
      }).subscribe(data => {
        if (data.success) {
          callback(data.response);
        }
      });
    }

  public saveComment(commentID: number, comment: string, response: string, acept: boolean): Observable<ApiResponse<BlogComments>> {
      return this.postAuthObject(environment.baseUrl + '/blog/save-comment', { id: commentID, comment: comment, response: response, acept: acept});
  }
  
}
