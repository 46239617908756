import { Injectable } from '@angular/core';
import { MiaAuthHttpService, AuthenticationService, MIAUser } from '@mobileia/authentication';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse, ApiPagination, MIATableModel } from '@mobileia/core';
import { environment } from '../../environments/environment';
import { Doctor } from '../entities/doctor';
import { Speciality } from '../entities/speciality';
import { Prepaga } from '../entities/prepaga';

@Injectable({
  providedIn: 'root'
})
export class DoctorService extends MiaAuthHttpService {

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    super(http, authService);
  }

  public fetchById(doctorId: number): Observable<ApiResponse<Doctor>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctor/fetch', { id: doctorId });
  }

  public delete(doctorId: number): Observable<ApiResponse<Doctor>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctor/delete', { id: doctorId });
  }

  public save(doctorId: number, name: string, data: any): Observable<ApiResponse<Doctor>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctor/save', { id: doctorId, name: name, data: data });
  }

  public fetchPrepagasByQuery(query: string): Observable<ApiResponse<[Prepaga]>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctor/fetch-prepagas', { query: query });
  }

  public fetchSpecialitiesByQuery(query: string): Observable<ApiResponse<[Speciality]>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctor/fetch-specialities', { query: query });
  }

  public fetchAll(tableModel: MIATableModel): Observable<ApiResponse<ApiPagination<any>>> {
    return this.postAuthObject(environment.baseUrl + 'admin/doctors', tableModel.toParams());
  }
}
