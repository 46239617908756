import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommentsService } from 'src/app/services/comments.service';

@Component({
  selector: 'app-comments',
  templateUrl: './company-comments.component.html',
  styleUrls: ['./company-comments.component.scss']
})


export class CompanyCommentsComponent implements OnInit {

  companies = [];
  filterCompany = -1;
  comments = [];
  isLoading = true;
  all_selected_values: number[] = [];

  columnsTable = [
    {title: 'Sel'},
    {title: 'Compañía'},
    {title: 'Nombre'},
    {title: 'Email'},
    {title: 'Opinión'},
    {title: 'Creado'},
    {title: 'Acción'},
  ];


  constructor(
    private commentsService: CommentsService,
  ) { }

  ngOnInit() {

    this.loadComments();
  }
  
  loadComments()
  {
    this.commentsService.fetchAll(data =>{
      this.comments = data;
      //console.log(this.comments);
      this.isLoading = false;
    })

  }

  aceptar(itemId)
  {
    if(confirm('¿Desea Aceptar el comentario?')){
      this.isLoading = true;
      this.commentsService.aceptCompanyComment(itemId, data => {
        this.loadComments();
      });
    }else{
      return false;
    }
  }

  removeItem(itemId)
  {
      this.isLoading = true;
      this.commentsService.deleteCompanyComment(itemId, data => {
        this.loadComments();
      });     
  }

  eliminarSeleccion()
  {
    if(this.all_selected_values.length > 0)
    {
      if(confirm('¿Está seguro de continuar con la eliminación, no se podrá revertir?'))
      {
        this.isLoading = true;
        this.all_selected_values.forEach(element => {
          this.commentsService.deleteCompanyComment(element, data => {
          });     

        });
        this.all_selected_values = [];
        this.loadComments();
      }
      return false;
    }

    alert('No hay objetos seleccionados.');

  }

  //Función para guardar los check seleccionados
  onChange(value: number): void {
    if (this.all_selected_values.includes(value)) {
      this.all_selected_values = this.all_selected_values.filter((item) => item !== value);
    } else {
      this.all_selected_values.push(value);
    }
    //console.log(this.all_selected_values);
  }
}
