export class UpdateProbabilityForm {

  public constructor(
    public readonly companyId: number,
    public readonly probability: number,
    public readonly residenceId: number,
    public readonly sellerId: number,
  ){}
}

