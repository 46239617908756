import { Component, OnInit } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../services/plan.service';
import { SanatoriumService } from '../services/sanatorium.service';

@Component({
  selector: 'app-create-sanatorium',
  templateUrl: './create-sanatorium.component.html',
  styleUrls: ['./create-sanatorium.component.scss']
})
export class CreateSanatoriumComponent implements OnInit {

  sanatorium = {id: -1, name: "", address: '', website: "", lng: 0, lat: 0, score: 0};

  constructor(private route: ActivatedRoute,
    private planService: PlanService,
    private sanatoriumService: SanatoriumService,
    private router: Router) {
      // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idSanatorium = params['id'];
      console.log(idSanatorium);
      if (idSanatorium !== undefined) {
        this.sanatorium = this.sanatoriumService.getSanatoriumById(idSanatorium);
        if (this.sanatorium === null){
          this.router.navigate(['/sanatoriums']);
        }
        console.log(this.sanatorium);
      }
    });
    }

  ngOnInit() {
  }

  public handleAddressChange(address: Address) {
    // Guardamos datos obtenidos
    // this.sanatorium.address = address.formatted_address;
    this.sanatorium.lat = address.geometry.location.lat();
    this.sanatorium.lng = address.geometry.location.lng();
  }

  onSubmit() {
    this.sanatoriumService.create(this.sanatorium, data => {
      this.router.navigate(['/sanatoriums']);
    });
  }
}
