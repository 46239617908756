import { Component, OnInit } from '@angular/core';
import { PlanService } from '../../services/plan.service';
import { CompanyService } from '../../services/company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss']
})
export class CreatePlanComponent implements OnInit {

  // tslint:disable-next-line:max-line-length
  plan = {id: -1, name: '', company_id: 0, has_copayment: 0, advantages: [], ages: [], persons: [], qualifications: [], childs: [], marriages: [], additional_cost: [], additional_cost_for_family_size: []};
  companies = [];

  constructor(private companyService: CompanyService,
  private planService: PlanService,
  private router: Router) {

  }

  ngOnInit() {
    this.loadCompanies();
  }

  loadCompanies() {
    this.companyService.fetchAll(data => {
      this.companies = data;
    });
  }

  onSubmit() {
    this.planService.create(this.plan, data => {
      this.router.navigate(['/plan/edit/' + data.id]);
    });
  }
}
