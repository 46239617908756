import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.scss']
})
export class PricePlanComponent implements OnInit {

  // Almacena que tab esta abierta
  // 1 Particular 2 Monotributista 3 Relación de dependencia
  activeTab = 3;
  // Almacenamos el plan abierto, child_age_parent : false
  plan = { id: -1, name: '', advantages: [], ages: [],
  persons: [], qualifications: [], childs: [], marriages: [],
  additional_cost: [], additional_cost_for_family_size: [], child_age_parent : false, family_discount: [], special_offer: [] };
  // Copiar precios
  copyType = 1;

  constructor(private route: ActivatedRoute,
    private planService: PlanService,
    private router: Router
  ) {
    
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var idPlan = params['id'];
      if(idPlan != undefined){
        // Buscar plan en el server
        this.planService.fetchById(idPlan, data => {
          this.plan = data;
          this.plan.child_age_parent = false;
        });
      }
    });
   }

  ngOnInit() {
  }

  onAddIndividual(){
    this.plan.persons.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, affiliate_type_id: this.activeTab, price: 0});
  }
  onChangeIndividual(person){
    this.planService.editPerson(person.id, person.plan_id, person.age_min, person.age_max, person.affiliate_type_id, person.price, data => {
      person.id = data.id;
    });
  }

  onDeletePerson(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.persons, person);
    }else{
      this.planService.deletePerson(person.id, data => {
        this.deleteItemInArray(this.plan.persons, person);
      })
    }
  }

  onAddMarriage(){
    this.plan.marriages.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, ref_age_min: 0, ref_age_max: 0, affiliate_type_id: this.activeTab, price: 0});
  }
  onChangeMarriage(marriage){
    this.planService.editMarriage(marriage, data => {
      marriage.id = data.id;
    });
  }
  onDeleteMarriage(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.marriages, person);
    }else{
      this.planService.deleteMarriage(person.id, data => {
        this.deleteItemInArray(this.plan.marriages, person);
      })
    }
  }

  onAddChild(){
    this.plan.childs.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, affiliate_type_id: this.activeTab, price: 0});
  }
  onChangeChild(child){
    this.planService.editChild(child, data => {
      child.id = data.id;
    });
  }
  onDeleteChild(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.childs, person);
    }else{
      this.planService.deleteChild(person.id, data => {
        this.deleteItemInArray(this.plan.childs, person);
      })
    }
  }
  onChangeChildAgeParent(){
    if(this.plan.child_age_parent){
      this.plan.child_age_parent = false;
    }else{
      this.plan.child_age_parent = true;
    }
  }

  onAddAdditionalCost(){
    this.plan.additional_cost.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, con_cgr: 1, rank_min: null, rank_max: null, affiliate_type_id: this.activeTab, price: 0});
  }
  onChangeAdditionalCost(additional){
    this.planService.editAdditionalCost(additional, data => {
      additional.id = data.id;
    });
  }
  onDeleteAdditionalCost(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.additional_cost, person);
    }else{
      this.planService.deleteAdditionalCost(person.id, data => {
        this.deleteItemInArray(this.plan.additional_cost, person);
      })
    }
  }

  onAddAdditionalCostForFamilySize(){
    this.plan.additional_cost_for_family_size.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, con_cgr: 1, affiliate_type_id: this.activeTab, price: 0});
  }
  onChangeAdditionalCostForFamilySize(additional){
    this.planService.editAdditionalCostForFamilySize(additional, data => {
      additional.id = data.id;
    });
  }
  onDeleteAdditionalCostForFamily(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.additional_cost_for_family_size, person);
    }else{
      this.planService.deleteAdditionalCostFamily(person.id, data => {
        this.deleteItemInArray(this.plan.additional_cost_for_family_size, person);
      })
    }
  }

  onAddFamilyDiscount(){
    this.plan.family_discount.push({id: -1, plan_id: this.plan.id, people_min: 0, people_max: 10, affiliate_type_id: this.activeTab, discount_percent: 0});
  }
  onChangeFamilyDiscount(discount){
    this.planService.editFamilyDiscount(discount, data => {
      discount.id = data.id;
    });
  }
  onDeleteDiscountFamily(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.family_discount, person);
    }else{
      this.planService.deleteDiscountFamily(person.id, data => {
        this.deleteItemInArray(this.plan.family_discount, person);
      })
    }
  }

  onAddSpecialOffer(){
    this.plan.special_offer.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 10, affiliate_type_id: this.activeTab, discount_permil: 0, short_description: "", long_description: "", start_date: ""});
  }
  onChangeSpecialOffer(special){
    this.planService.editSpecialOffer(special, data => {
      special.id = data.id;
    });
  }
  onDeleteSpecialOffer(person){
    if(person.id == -1){
      this.deleteItemInArray(this.plan.special_offer, person);
    }else{
      this.planService.deleteSpecialOffer(person.id, data => {
        this.deleteItemInArray(this.plan.special_offer, person);
      })
    }
  }

  onClickTab(id){
    this.activeTab = id;
  }

  deleteItemInArray(arr, item){
    var index = arr.indexOf(item, 0);
      if (index > -1) {
        arr.splice(index, 1);
      }
  }

  onCopyType() {
    if (this.activeTab == this.copyType) {
      alert('No se permite realizar esta acción.');
      return false;
    }
    this.planService.copyPrices(this.plan.id, this.activeTab, this.copyType, data => {
      this.router.navigate(['/plans']);
    });
  }

  messageInModalCopy() {
    var string = 'Usted esta por pasar todos los valores del "';
    if (this.activeTab == 1) {
      string += 'Particular';
    } else if (this.activeTab == 2) {
      string += 'Monotributista';
    } else if (this.activeTab == 3) {
      string += 'Relación de dependencia';
    }
    string += '" a ';
    if (this.copyType == 1) {
      string += 'Particular';
    } else if (this.copyType == 2) {
      string += 'Monotributista';
    } else if (this.copyType == 3) {
      string += 'Relación de dependencia';
    }
    string += ', tenga en cuenta que todos los valores de "';
    if (this.copyType == 1) {
      string += 'Particular';
    } else if (this.copyType == 2) {
      string += 'Monotributista';
    } else if (this.copyType == 3) {
      string += 'Relación de dependencia';
    }
    string += '" que existen en este momento seran borrados.';
    return string;
  }
}
