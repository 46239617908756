import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog-relation',
  templateUrl: './blog-relation.component.html',
  styleUrls: ['./blog-relation.component.scss']
})
export class BlogRelationComponent implements OnInit {

  item: any;
  isLoading = true;
  relations = [];

  newTitle = '';
  newUrl = '';

  constructor(
    private router: Router,
    private blogService : BlogService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      var slug = params['slug'];
      if(slug != undefined){
        this.loadItem(slug);
      } else {
        this.router.navigateByUrl('/blog');
      }
    });
  }

  onClickSave() {
    this.blogService.saveRelation({slug: this.item.slug, title: this.newTitle, url: this.newUrl}).toPromise().then(data => {
      this.loadRelations();
    });
  }

  removeItem(item){
    this.blogService.removeRelation(item.id).toPromise().then();
    this.relations.splice(this.relations.indexOf(item), 1);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result == '100') {
        this.onClickSave();
      }
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return false;
  }

  loadRelations() {
    this.isLoading = true;
    this.blogService.fetchAllRelation(this.item.slug).toPromise().then(data => {
      if(!data.success){
        return;
      }
      this.relations = data.response;
      this.isLoading = false;
    });
  }

  loadItem(slug: number) {
    this.blogService.fetchBySlug(slug).toPromise().then(data => {
      if (!data.success) {
        this.router.navigate(['/residency']); 
        return;
      }

      this.item = data.response;
      this.loadRelations();
    });
  }
}
