import {environment} from "../../../../environments/environment";

export class EndpointsUrl {
  endpoint: string;
  private url: string = environment.javaBaseUrl;

  private endpointUrl(route: string): string {
    return `${this.url}${this.endpoint}/${route}`;
  }

  protected getAllUrl(): string {
    return this.endpointUrl('all');
  }

  protected createUrl(): string {
    return this.endpointUrl('create');
  }

  protected updateUrl(id: number): string {
    return this.endpointUrl(`update/${id}`);
  }

  protected deleteUrl(id: number): string {
    return this.endpointUrl(`delete/${id}`);
  }

  protected getUrl(id: number): string {
    return this.endpointUrl(`${id}`);
  }

  protected otherUrl(endpoint: string): string {
    return this.endpointUrl(`${endpoint}`);
  }
}
