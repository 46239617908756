import {Component, OnInit} from '@angular/core';
import {CompanyService} from '../../services/company.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  companies = [];
  selectedCompany = {id: 0, name: ''};
  selectedSignal = '0';
  selectedAmount = null;

  constructor(
    private companyService: CompanyService,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadCompanies();
  }

  removeItem(item) {
    this.companyService.deleteCompany(item.id, data => {
    });
    this.companies.splice(this.companies.indexOf(item), 1);
  }

  loadCompanies() {
    this.companyService.fetchAll(data => {
      console.log('responde data compañia' + data);
      this.companies = data;
    });
    console.log('responde compañia' + this.companies);
  }

  applyPercent() {
    this.companyService.applyPercent(this.selectedCompany.id, this.selectedSignal, this.selectedAmount, data => {

    });
  }

  open(content, item) {
    this.selectedCompany = item;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result == '100') {
        this.applyPercent();
      }
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    return false;
  }

  goToCompanySellers(companyId: number, companyName: string): void {
    console.log('Company ID: ', companyId);
    this.router.navigate(['company/sellers'], {
      queryParams: {
        companyId: companyId,
        companyName: companyName
      }
    })
  }
}
