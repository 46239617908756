import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanService } from '../../services/plan.service';
import {split} from 'ts-node';
import {ResidencyService} from '../../services/residency.service';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit {

  plan = { id: -1, name: '', has_copayment: 0, advantages: [],
  advantages_obj: [], advantages_new: [], ages: [], persons: [], qualifications: [],
  childs: [], marriages: [], additional_cost: [], additional_cost_for_family_size: [], active: 1,
  score: 0, score_sur: 0, score_norte: 0, score_oeste: 0, score_caba: 0, is_score: 0, residency_id: undefined };

  residencies = [];

  dropdownSettings: any = {};

  constructor(private route: ActivatedRoute,
    private planService: PlanService,
    private residencyService: ResidencyService,
    private router: Router
  ) {

    // Buscamos si es edición
    this.route.params.subscribe(params => {
      const idPlan = params['id'];
      if (idPlan !== undefined) {
        // Buscar plan en el server
        this.planService.fetchById(idPlan, data => {
          this.plan = data;
          this.convertAdvantages();
          this.getResidencies();
        });
      }
    });
  }

  getResidencies() {
    this.residencyService.fetchAll().toPromise().then(data => {
      const response = data.response;
      console.log("response");
      const aux = this.plan.residency_id.split(',');
      this.plan.residency_id = [];
      for (let i = 4; i < response.length; i++) {
        const element = {id: response[i]['id'], name: response[i]['name']};
        this.residencies.push(element);
        if (aux.includes(element.id.toString())) {
          this.plan.residency_id.push(element);
        }
      }
    });
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      closeDropDownOnSelection: false,
      idField: 'id',
      textField: 'name',
    };
  }

  onAddAge() {
    this.plan.ages.push({id: -1, plan_id: this.plan.id, age_min: 0, age_max: 100, affiliate_type_id: 1});
  }

  onChangeAge(age) {
    this.planService.editAge(age, data => {
      age.id = data.id;
    });
  }

  onDeleteAge(age) {
    if (age.id === -1) {
      const index = this.plan.ages.indexOf(age, 0);
      if (index > -1) {
        this.plan.ages.splice(index, 1);
      }
    } else {
      this.planService.deleteAge(age.id, data => {
        const index = this.plan.ages.indexOf(age, 0);
        if (index > -1) {
          this.plan.ages.splice(index, 1);
        }
      });
    }
  }

  onAddAdvantage() {
    this.plan.advantages_obj.push({ text : ''});
  }

  onDeleteAdvantage(advantage) {
    const index = this.plan.advantages_obj.indexOf(advantage, 0);
      if (index > -1) {
        this.plan.advantages_obj.splice(index, 1);
      }
  }

  onAddAdvantageNew() {
    if (this.plan.advantages_new === undefined) {
      this.plan.advantages_new = [];
    }
    this.plan.advantages_new.push({ icon : '', text: '' });
  }

  onDeleteAdvantageNew(advantage) {
    const index = this.plan.advantages_new.indexOf(advantage, 0);
      if (index > -1) {
        this.plan.advantages_new.splice(index, 1);
      }
  }

  onChangeQualification(item) {
    this.planService.editQualification(this.plan.id, item.id, item.plan_value, data => {

    });
  }

  onClickBack() {
    this.router.navigate(['/plans']);
  }

  onClickSave() {
    const data = [];
    for (let i = 0; i < this.plan.advantages_obj.length; i++) {
      const element = this.plan.advantages_obj[i];
      data.push(element.text);
    }
    this.plan.residency_id = this.plan.residency_id.map(r => r.id);

    this.plan.advantages = data;

    // tslint:disable-next-line:no-shadowed-variable
    this.planService.edit(this.plan, data => {
      this.router.navigate(['/plans']);
    });
  }

  private convertAdvantages() {
    const data = [];
    for (let i = 0; i < this.plan.advantages.length; i++) {
      const element = this.plan.advantages[i];
      data.push({ text: element });
    }
    this.plan.advantages_obj = data;
  }
}
