import {Injectable} from "@angular/core";
import {EndpointsUrl} from "./endpointsUrl";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Residency} from "../../../entities/models/residency/residency";

@Injectable({
  providedIn: 'root'
})
export class ResidencyJavaService extends EndpointsUrl {
  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super();
    this.endpoint = "residency";
  }

  public getAll(): Promise<Residency[]> {
    return this.httpClient
      .get<Residency[]>(this.getAllUrl())
      .toPromise()
  }
}
