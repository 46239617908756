import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from '@mobileia/layout-elite';
import {BrowserModule} from '@angular/platform-browser';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthGuard} from '@mobileia/authentication';
import {CompaniesComponent} from './pages/companies/companies.component';
import {AddCompanyComponent} from './pages/add-company/add-company.component';
import {PlansComponent} from './plans/plans.component';
import {EditPlanComponent} from './pages/edit-plan/edit-plan.component';
import {PricePlanComponent} from './pages/price-plan/price-plan.component';
import {SanatoriumPlanComponent} from './pages/sanatorium-plan/sanatorium-plan.component';
import {CreatePlanComponent} from './pages/create-plan/create-plan.component';
import {SanatoriumsComponent} from './pages/sanatoriums/sanatoriums.component';
import {CreateSanatoriumComponent} from './create-sanatorium/create-sanatorium.component';
import {BenefitPlanComponent} from './pages/benefit-plan/benefit-plan.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {PopupComponent} from './pages/popup/popup.component';
import {UsersComponent} from './pages/users/users.component';
import {AddUserComponent} from './pages/add-user/add-user.component';
import {DoctorsComponent} from './pages/doctors/doctors.component';
import {AddDoctorComponent} from './pages/add-doctor/add-doctor.component';
import {PageTagsComponent} from './pages/page-tags/page-tags.component';
import {PageTagsAddComponent} from './pages/page-tags-add/page-tags-add.component';
import {ResidencyComponent} from './pages/residency/residency.component';
import {ResidencyAddComponent} from './pages/residency-add/residency-add.component';
import {FormsComponent} from './pages/forms/forms.component';
import {BlogComponent} from './pages/blog/blog.component';
import {BlogRelationComponent} from './pages/blog-relation/blog-relation.component';
import {SellersComponent} from "./pages/companies/sellers/sellers.component";
import {SellerFormComponent} from "./pages/companies/seller-form/seller-form.component";
import {SellersCompanyComponent} from "./pages/companies/sellers-company/sellers-company.component";
import {SellerCompanyFormComponent} from "./pages/companies/seller-company-form/seller-company-form.component";
import {MailingComponent} from "./pages/mailing/mailing.component";
import {CompanyCommentsComponent} from "./pages/company-comments/company-comments.component";
import { BlogCommentsComponent } from './pages/blog-comments/blog-comments.component';
import { EditBlogCommentComponent } from './pages/edit-blog-comment/edit-blog-comment.component';
import { EditCompanyCommentComponent } from './pages/edit-company-comment/edit-company-comment.component';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginPageComponent,
    data: {"success_route": "dashboard"}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'companies/:id',
    component: CompaniesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company/sellers',
    component: SellersCompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company/seller-company-form',
    component: SellerCompanyFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sellers',
    component: SellersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sellers/form',
    component: SellerFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company/add',
    component: AddCompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company/edit/:id',
    component: AddCompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    component: PlansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plan/edit/:id',
    component: EditPlanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plan/price/:id',
    component: PricePlanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plan/sanatorium/:id',
    component: SanatoriumPlanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plan/prestaciones/:id',
    component: BenefitPlanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plan/create',
    component: CreatePlanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sanatoriums',
    component: SanatoriumsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sanatorium/create',
    component: CreateSanatoriumComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sanatorium/edit/:id',
    component: CreateSanatoriumComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'popups',
    component: PopupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    component: UsersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios/add',
    component: AddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cartilla',
    component: DoctorsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cartilla/add',
    component: AddDoctorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cartilla/edit/:id',
    component: AddDoctorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tags',
    component: PageTagsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'page-tags/edit/:id',
    component: PageTagsAddComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'residency',
    component: ResidencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'residency/add',
    component: ResidencyAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'residency/edit/:id',
    component: ResidencyAddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    component: FormsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blog',
    component: BlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blog-relation/:slug',
    component: BlogRelationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mailing',
    component: MailingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-comments',
    component: CompanyCommentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blog-comments',
    component: BlogCommentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blog-comment/edit/:id',
    component: EditBlogCommentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company-comment/edit/:id',
    component: EditCompanyCommentComponent,
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule {
}
