import { Component, OnInit } from '@angular/core';
import { LayoutTableService, LayoutMenuService } from '@mobileia/layout-elite';
import { AuthenticationService } from '@mobileia/authentication';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponse, ApiPagination, MIATableModel } from '@mobileia/core';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {

  columnTable = [
    { title: 'ID', filter: 'id', class: 'text-nowrap'},
    { title: 'Nombre', filter: 'name' },
    { title: 'Acciones', class: 'text-nowrap' }
  ];
  isLoading = true;
  tableModel = new MIATableModel();
  dataItems = new ApiPagination<any>();
  newDoctor = {};

  constructor(
    private tableService: LayoutTableService,
    private doctorService: DoctorService,
    private authService: AuthenticationService,
    private menuService: LayoutMenuService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    // Observar cuando se cambian los filtros
    this.tableService.getFilterObservable().subscribe(filter => {
      this.onChangeFilter(filter);
    });
    this.loadItems();
  }

  onDelete(doctor: any) {
    this.doctorService.delete(doctor.id).subscribe(data => {
      if (data.success) {
        window.location.reload();
        //this.dataItems.data = this.dataItems.data.filter(cat => cat.id !== doctor.id);
      }
    });
    /*this.userService.delete(user.id).subscribe(data => {
      if (data.success) {
        window.location.reload();
        //this.dataItems.data = this.dataItems.data.filter(cat => cat.id !== category.id);
      }
    });*/
  }

  loadItems() {
    this.isLoading = true;
    this.doctorService.fetchAll(this.tableModel).subscribe(data => {
      if (!data.success) {
        return;
      }
      this.tableModel.setPagination(data.response.last_page, 10);
      this.dataItems = data.response;
      this.isLoading = false;
    });
  }

  onChangeFilter(newFilter){
    // Verificar si se seleccinoo un filtro correcto
    if (newFilter.title == '' || newFilter.title == undefined) {
      return;
    }
    // Asignamos nuevo filtro
    this.tableModel.ordType.title = newFilter.title;
    this.tableModel.ordType.asc = newFilter.asc;
    // Reiniciar paginas
    this.tableModel.pageCurrent = 1;
    // Volver a cargar los eventos
    this.loadItems();
  }

  onClickPage(page: number) {
    this.tableModel.pageCurrent = page;
    this.loadItems();
  }

  onSendSearch() {
    this.loadItems();
  }
}
