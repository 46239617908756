import {AfterContentInit, Component, Renderer2} from '@angular/core';
import {AuthenticationService} from '@mobileia/authentication';
import {Router} from '@angular/router';
import {LayoutMenuService} from '@mobileia/layout-elite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit {

  title = 'app';
  isLogged = false;
  private userID:any = null;

  topbarMenu = [
    {
      id: 1,
      title: 'Mi perfil',
      icon: 'fa fa-user'
    },
    {
      separator: true
    },
    {
      id: 2,
      title: 'Salir',
      icon: 'fa fa-power-off'
    }];

  sidebarMenu = [
    {
      is_group: true,
      title: '--- MENU PRINCIPAL'
    },
    {
      id: 1,
      title: 'Dashboard',
      icon: 'fa fa-tachometer-alt',
    },
    {
      id: 2,
      title: 'Compañias',
      icon: 'far fa-building',
    },
    {
      id: 3,
      title: 'Planes',
      icon: 'fas fa-globe',
    },
    {
      id: 4,
      title: 'Sanatorios',
      icon: 'fas fa-hospital-alt',
    },
    {
      id: 5,
      title: 'Pedidos',
      icon: 'fas fa-clipboard-check',
    },
    {
      id: 6,
      title: 'Popup',
      icon: 'fab fa-searchengin',
    },
    {
      id: 11,
      title: 'Formularios',
      icon: 'fas fa-file-signature',
    },
    {
      id: 7,
      title: 'Usuarios',
      icon: 'fas fa-user',
    },
    {
      id: 8,
      title: 'Medicos',
      icon: 'fas fa-user-md',
    },
    {
      id: 13,
      title: 'Vendedores',
      icon: 'fas fa-users',
    },
    {
      id: 14,
      title: 'Emails',
      icon: 'far fa-envelope',
    },
    {
      id: 9,
      title: 'Tags',
      icon: 'fas fa-tags',
    },
    {
      id: 10,
      title: 'Residencias',
      icon: 'fas fa-map',
    },
    {
      id: 12,
      title: 'Blogs',
      icon: 'fas fa-book',
    },
    {
      id: 20,
      title: 'Opiniones de Compañías',
      icon: 'fas fa-comments',
    },
    {
      id: 21,
      title: 'Opiniones del Blog',
      icon: 'fas fa-comments',
    }
  ];

  constructor(private authService: AuthenticationService,
              private router: Router,
              private menuService: LayoutMenuService,
              private renderer: Renderer2) {

    this.menuService.getSidebarMenuObservable().subscribe(id => {
      this.clickSidebarMenu(id);
    });

    this.menuService.getTopbarMenuObservable().subscribe(id => {
      this.clickTopbarMenu(id);
    });
    

    //var request = indexedDB.open("mia-authentication_ngStorage");
            

    this.authService.getCurrentUser().subscribe(user => {
      this.userID = user;
      if (user == null) {
        return;
      }
      
      this.isLogged = true;
      if (user.id == 3588) {
        return;
      }
      // Configurar menu para estos usuarios
      this.sidebarMenu = [
        {
          is_group: true,
          title: '--- MENU PRINCIPAL'
        },
        {
          id: 5,
          title: 'Pedidos',
          icon: 'fas fa-clipboard-check',
        },
        {
          id: 6,
          title: 'Popup',
          icon: 'fab fa-searchengin',
        },
        {
          id: 11,
          title: 'Formularios',
          icon: 'fas fa-file-signature',
        },
        {
          id: 14,
          title: 'Emails',
          icon: 'far fa-envelope',
        },
        {
          id: 20,
          title: 'Opiniones de Compañías',
          icon: 'fas fa-comments',
        },
        {
          id: 21,
          title: 'Opiniones del Blog',
          icon: 'fas fa-comments',
        }

      ];
    });
  }

  public ngAfterContentInit(): void {
    if (this.renderer == null) {
      return;
    }

    this.renderer.addClass(document.body, 'mini-sidebar');
    setTimeout(() => {
      this.renderer.addClass(document.body, 'mini-sidebar');
    }, 2000);
  }

  public clickTopbarMenu(id: number) {
    if (id == 2) {
      this.logout();
    }
  }

  public clickSidebarMenu(id: number) {
    if (id == 1) {
      this.router.navigateByUrl('/dashboard');
    } else if (id == 2) {
      this.router.navigateByUrl('/companies');
    } else if (id == 3) {
      this.router.navigateByUrl('/plans');
    } else if (id == 4) {
      this.router.navigateByUrl('/sanatoriums');
    } else if (id == 5) {
      this.router.navigateByUrl('/contacts');
    } else if (id == 6) {
      this.router.navigateByUrl('/popups');
    } else if (id == 7) {
      this.router.navigateByUrl('/usuarios');
    } else if (id == 8) {
      this.router.navigateByUrl('/cartilla');
    } else if (id == 9) {
      this.router.navigateByUrl('/tags');
    } else if (id == 10) {
      this.router.navigateByUrl('/residency');
    } else if (id == 11) {
      this.router.navigateByUrl('/forms');
    } else if (id == 12) {
      this.router.navigateByUrl('/blog');
    } else if (id == 13) {
      this.router.navigateByUrl('/sellers');
    }else if (id == 14) {
      this.router.navigateByUrl('/mailing');
    }else if (id == 20) {
      this.router.navigateByUrl('/company-comments');
    }
    else if (id == 21) {
      this.router.navigateByUrl('/blog-comments');
    }
  }
  /**
   * logout
   */
  public logout() {
    this.authService.signOut();
    this.router.navigate(['/login']);
    this.isLogged = false;
  }
}
