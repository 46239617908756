import { Component, OnInit } from '@angular/core';
import { Pagetag } from 'src/app/entities/pagetag';
import { Router, ActivatedRoute } from '@angular/router';
import { PagetagsService } from 'src/app/services/pagetags.service';

@Component({
  selector: 'app-page-tags-add',
  templateUrl: './page-tags-add.component.html',
  styleUrls: ['./page-tags-add.component.scss']
})
export class PageTagsAddComponent implements OnInit {

  pageId = 0;
  page = new Pagetag();

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected pageService: PagetagsService
  ) { }

  ngOnInit() {
    // Buscamos si es edición
    this.route.params.subscribe(params => {
      this.pageId = params['id'];
      if (this.pageId !== undefined) {
        this.loadPage();
      }
    });
  }

  onClickSave() {
    this.pageService.save(this.page).subscribe(data => {
      if (data.success) {
        this.router.navigate(['/tags']);
      }
    });
  }

  onRemoveExtra(tag) {
    let index = this.page.tags.indexOf(tag);
    if (index >= 0) {
      this.page.tags.splice(index, 1);
    }
  }

  addExtra() {
    if (this.page.tags == undefined) {
      this.page.tags = new Array<{key: string, value: string}>();
    }
    this.page.tags.push({key: '', value: ''});
  }

  onClickBack() {
    this.router.navigate(['/tags']);
  }

  loadPage() {
    this.pageService.fetchById(this.pageId).subscribe(data => {
      if (data.success) {
        this.page = data.response;
      }
    });
  }
}
