import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@mobileia/authentication';
import { Router } from '@angular/router';
import { PagetagsService } from 'src/app/services/pagetags.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    protected pageService: PagetagsService,
    private router: Router) { }

  ngOnInit() {
    
    this.authService.getCurrentUser().subscribe(user => {
      if (user == null) {
        return;
      }
      if (user.id != 3588) {
        this.router.navigateByUrl('/contacts');
      }
    });

    
  }

  onClickCleanBlog() {
    this.pageService.cleanCacheBlog().toPromise().then(data => {});
  }
}
