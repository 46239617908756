import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Seller} from "../../../entities/models/sellers/seller";
import {SellerModel, sellerToJson} from "../../../entities/models/sellers/seller.model";
import {AuthService} from "./auth.service";
import {EndpointsUrl} from "./endpointsUrl";

@Injectable({
  providedIn: 'root'
})
export class SellerService extends EndpointsUrl {

  constructor(private httpClient: HttpClient,
              private authService: AuthService) {
    super();
    this.endpoint = "seller";
  }

  public postSeller(seller: SellerModel): Promise<Seller> {
    return this.httpClient
      .post<Seller>(this.createUrl(), sellerToJson(seller))
      .toPromise();
  }

  public putSeller(seller: SellerModel): Promise<Seller> {
    return this.httpClient
      .put<Seller>(this.updateUrl(seller.id), sellerToJson(seller))
      .toPromise();
  }

  public deleteSeller(sellerId: number): Promise<Seller> {
    return this.httpClient
      .delete<Seller>(this.deleteUrl(sellerId))
      .toPromise();
  }

  public getAll(): Promise<Seller[]> {
    return this.httpClient
      .get<Seller[]>(this.getAllUrl())
      .toPromise()
  }

  public getSeller(sellerId: number): Promise<Seller> {
    return this.httpClient
      .get<Seller>(this.getUrl(sellerId))
      .toPromise()
  }

}
