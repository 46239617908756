import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Seller} from "../../../entities/models/sellers/seller";
import {CompanyService} from "../../../services/company.service";
import {SellerService} from "../../../services/services/java/seller.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.scss']
})
export class SellersComponent implements OnInit {
  sellers: Seller[] = [];

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private sellerService: SellerService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.sellerService.getAll()
      .then(res => this.sellers = res)
      .catch(() => this.toastrService.error("¡Error al solicitar vendedores!", "Error"));
  }

  goToSellerForm(seller?: Seller): void {
    this.router.navigate(['sellers/form'], {
      queryParams: {sellerId: seller !== undefined ? seller.id : undefined}
    })
  }

  removeSeller(seller: Seller): void {
    this.sellerService.deleteSeller(seller.id)
      .then(() => {
        this.sellers = this.sellers.filter(s => s.id !== seller.id);
        this.toastrService.info("¡El vendedor ha sido eliminado!","Solicitud de Eliminación")
      })
  }

}
