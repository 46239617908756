import { Component, OnInit } from '@angular/core';
import { MiaDynamicFormService } from '@mobileia/core';
import { FormGroup } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  form: FormGroup;
  company = null;

  public fields: any[] = [
    {
      type: 'text',
      name: 'firstname',
      label: 'Nombre',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'lastname',
      label: 'Apellido',
      value: '',
      required: false,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Email',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'password',
      label: 'Contraseña',
      value: '',
      required: true,
    },
    {
      type: 'text',
      name: 'phone',
      label: 'Telefono',
      value: '',
      required: false,
    },
    {
      type: 'button',
      name: 'save',
      label: 'Guardar'
    }
  ];

  constructor(private dynamicFormService: MiaDynamicFormService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute) {
    // Preparamos formulario
    this.form = dynamicFormService.generateFormGroup(this.fields);
  }

  ngOnInit() {
  }

  onSend(values) {
    this.userService.create(values).subscribe(data => {
      if (data.success) {
        this.router.navigate(['/usuarios']);
      }
    });
  }
}
